export const updateBuildDemoStatus = (demo) => async (dispatch) => {
  try {
    dispatch({ type: "UPDATE_BUILD_DEMO_REQUEST" });

    dispatch({ type: "UPDATE_BUILD_DEMO_SUCCESS", payload: demo });
  } catch (error) {
    dispatch({
      type: "UPDATE_BUILD_DEMO_FAILED",
      payload: error,
    });
    throw error;
  }
};
export const setSelectedFilteredProducts =
  (productsData) => async (dispatch) => {
    try {
      dispatch({ type: "SET_SELECTED_PRODUCT_REQUEST" });

      dispatch({ type: "SET_SELECTED_PRODUCT_SUCCESS", payload: productsData });
    } catch (error) {
      dispatch({
        type: "SET_SELECTED_PRODUCT_FAILED",
        payload: error,
      });
      throw error;
    }
  };

export const setExcelCheckedAttributes =
  (checkedAttributes) => async (dispatch) => {
    try {
      console.log("checkedAttributes", checkedAttributes);
      dispatch({ type: "SET_EXCEL_CHECKED_ATTRIBUTES_REQUEST" });

      // Save the checkedAttributes to localStorage
      localStorage.setItem(
        "selectedCheckedAttributes",
        JSON.stringify(checkedAttributes)
      );

      dispatch({
        type: "SET_EXCEL_CHECKED_ATTRIBUTES_SUCCESS",
        payload: checkedAttributes,
      });
    } catch (error) {
      dispatch({
        type: "SET_EXCEL_CHECKED_ATTRIBUTES_FAILED",
        payload: error,
      });
      throw error;
    }
  };
