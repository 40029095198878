import React, { useCallback, useEffect, useRef, useState } from "react";
import PptxGenJS from "pptxgenjs";
import ProductPreview from "./ProductPreview";
import { connect } from "react-redux";
import { setSelectedFilteredProducts } from "../redux/action/others";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { getProductsFromIndexedDB } from "../components/IndexDB";
import bestSellerIcon from "../images/icons/bestSeller.svg";
import backArrow from "../images/back-arrow.svg"
import {
  pptLabel_base64,
  pptFirstPage,
  ppt_plus_icon,
  ppt_pearl_label,
  ppt_text_label,
  ppt_hollow,
  ppt_last_page,
} from "../images/PptLabel";
import { bestSellerBase64 } from "../images/icons/BestSellerIconBase64";
import html2canvas from "html2canvas";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { server } from "../redux/store";
import convertDate from "../components/Helper/ConvertDate";
import { RiArrowDropDownLine } from "react-icons/ri";
import pptPearlGlobalText from "../images/ppt-pearl-global-text.svg";
import pptHollow from "../images/ppt-hollow.svg";
import plusIcon from "../images/plus-icon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Toastify styles
import dayjs from "dayjs";
import {
  getImageDimensions,
  getProductImageDimensions,
  getAllProductImageDimensions,
} from "./ProductImageDimensionCalculator";
import { downloadExcelFile } from "../redux/action/addProduct";
// import { convertToPdf } from '../redux/action/addProduct';
const CHUNK_SIZE = 100000; // 100KB
function PptGenerator({
  selectedFilteredProductsData,
  setActiveItem,
  setDemoSelected,
  selectedCheckedAttributes,
}) {
  const [pptDate, setPptDate] = useState(dayjs().format("YYYY-MM-DD HH:mm:ss"));
  const viewRef = useRef(null);
  const [selectedFilteredProducts, setSelectedFilteredProducts] = useState([]);
  const [pptBase64String, setPptBase64String] = useState(null);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(-1);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [generate, setGenerate] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // Initialize to 0 for the first slide
  const [lastSlideVisible, setLastSlideVisible] = useState(false);
  const [firstSlideVisible, setFirstSlideVisible] = useState(true);
  const [checkedAttributes, setCheckedAttributes] = useState(null);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const navigate = useNavigate();
  const getProductsFromIndexedDb = async () => {
    try {
      // Retrieve products from IndexedDB
      const storedProducts = await getProductsFromIndexedDB(
        "productsDB",
        "products",
        1
      );
      console.log("Retrieved products:", storedProducts);
      setSelectedFilteredProducts(storedProducts);
      return storedProducts;
      // Now you can add more operations like displaying the retrieved products
    } catch (error) {
      console.error("Error retrieving products:", error);
      return []; // Return an empty array or handle the error as needed
    }
  };

  useEffect(() => {
    const ids = selectedFilteredProducts.map((item) => item._id);
    const uniqueIds = [...new Set(ids)];
    setSelectedProductIds(uniqueIds);
  }, [selectedFilteredProducts]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  useEffect(() => {
    getProductsFromIndexedDb();
  }, []);
  console.log(selectedFilteredProducts);
  getAllProductImageDimensions(selectedFilteredProducts).then((results) => {
    console.log(results);
  });
  useEffect(() => {
    if (selectedCheckedAttributes && selectedCheckedAttributes.length > 0) {
      // Update local state and localStorage if Redux state changes
      setCheckedAttributes(selectedCheckedAttributes);
    } else {
      // Load from localStorage if Redux state is not available
      const storedCheckedAttributes = localStorage.getItem(
        "selectedCheckedAttributes"
      );
      if (storedCheckedAttributes) {
        setCheckedAttributes(JSON.parse(storedCheckedAttributes)); // Parse the JSON string
      }
    }
  }, [selectedCheckedAttributes]);

  useEffect(() => {
    // Function to set the --vh variable
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    // Initial call to set the --vh value
    setVh();

    // Update the --vh value on window resize
    window.addEventListener("resize", setVh);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);

  console.log(checkedAttributes, selectedProductIds);
  const handleGeneratePpt = useCallback(async () => {
    if (!generate) return;
    const toastId = toast.info("Downloading PDF... Please wait.", {
      autoClose: false, // Keep it visible until the download completes
      position: "top-right",
    });

    // Get image dimensions first
    const imageDimensions = await getAllProductImageDimensions(
      selectedFilteredProducts
    );
    console.log(imageDimensions);

    const pptx = new PptxGenJS();
    const slide0 = pptx.addSlide();

    slide0.addImage({
      data: pptFirstPage,
      x: "0%",
      y: "0%",
      w: "100%",
      h: "100%",
    });
    slide0.addImage({
      data: ppt_text_label,
      x: "25%",
      y: "45%",
      w: "50%",
      h: "20%",
      sizing: { type: "contain" },
    });
    slide0.addImage({
      data: ppt_plus_icon,
      x: "90%",
      y: "80%",
      w: "5%",
      h: "8%",
      sizing: { type: "contain" },
    });
    slide0.addImage({
      data: ppt_hollow,
      x: "33%",
      y: "0%",
      w: "33%",
      h: "33%",
      sizing: { type: "contain" },
    });
    slide0.addText(
      `Ppt Generated on ${dayjs().format("YYYY-MM-DD HH:mm:ss")}`,
      {
        x: "2%",
        y: "90%",
        w: "20%",
        h: "5%",
        fontSize: 6.5,
        color: "8aaa78",
      }
    );
    const estimateTextHeight = (text, fontSize, lineWidth) => {
      const charWidth = fontSize * 0.6; // Average character width estimate
      const maxCharsPerLine = lineWidth / charWidth;
      const lineCount = Math.ceil(text.length / maxCharsPerLine);
      return lineCount * fontSize * 1.2; // line height with 1.2 as a multiplier for line spacing
    };
    selectedFilteredProducts.forEach((product, index) => {
      const productDimensions = imageDimensions.find(
        (dim) => dim._id === product._id
      );

      // Slide 1: Front image and details
      const slide1 = pptx.addSlide();

      slide1.addText("THIS PRESENTATION IS CREATED BY PEARL AI PLATFORM", {
        x: "2%",
        y: "95%",
        w: "20%",
        h: "5%",
        fontSize: 4,
      });

      slide1.addImage({
        data: ppt_plus_icon,
        x: "90%",
        y: "80%",
        w: "5%",
        h: "8%",
        sizing: { type: "contain" },
      });

      slide1.addImage({
        data: ppt_pearl_label,
        x: "85%",
        y: "95%",
        w: "12%",
        h: "3%",
        sizing: { type: "contain" },
      });

      const calculateHeightAndYPosition = (
        imageSize,
        desiredHeight,
        desiredWidth
      ) => {
        // A4 landscape dimensions (width: 1920px, height: 1080px)
        const slideWidth = 1920;
        const slideHeight = 1080;

        // Calculate the aspect ratio of the image
        const imageAspectRatio = imageSize.height / imageSize.width;

        // Calculate the height percentage relative to the slide's actual height
        const heightPercentage =
          (imageAspectRatio * slideWidth * desiredWidth) / slideHeight;

        // Calculate the vertical centering position
        const yPosition = `${(desiredHeight - heightPercentage) / 2}%`;

        return {
          w: `${desiredWidth}%`,
          h: `${heightPercentage}%`,
          y: yPosition,
        };
      };
      const calculateWidthAndXPosition = (
        imageSize,
        desiredWidth,
        desiredHeight
      ) => {
        // A4 landscape dimensions (width: 1920px, height: 1080px)
        const slideWidth = 1920;
        const slideHeight = 1080;

        // Calculate the aspect ratio of the image
        const imageAspectRatio = imageSize.width / imageSize.height;

        // Calculate the width percentage relative to the slide's actual width
        const widthPercentage =
          (imageAspectRatio * slideHeight * desiredHeight) / slideWidth;

        // Calculate the horizontal centering position
        const xPosition = `${(desiredWidth - widthPercentage) / 2}%`;

        return {
          w: `${widthPercentage}%`,
          h: `${desiredHeight}%`,
          x: xPosition,
        };
      };

      // For the front image
      const frontImageSize = productDimensions.dimensions.frontImage;
      const frontDimensions = calculateHeightAndYPosition(
        frontImageSize,
        100,
        24
      );
      slide1.addImage({
        data: product.front_image_base64,
        x: "2%",
        ...frontDimensions,
        sizing: {
          type: "contain",
          x: "2%",
          ...frontDimensions,
        },
      });

      // For the back image
      const backImageSize = productDimensions.dimensions.backImage;
      const backDimensions = calculateHeightAndYPosition(
        backImageSize,
        100,
        24
      );
      slide1.addImage({
        data: product.back_image_base64,
        x: "27%",
        ...backDimensions,
        sizing: {
          type: "contain",
          x: "2%",
          ...backDimensions,
        },
      });
      if (product.best_seller) {
        slide1.addImage({
          data: bestSellerBase64,
          x: "5%",
          y: "1%",
          w: "5%",
          h: "12%",
          sizing: { type: "contain", x: "88%", y: "1%", w: "5%", h: "12%" },
        });
      }

      const closeUpImagePositions = [
        { x: "52%", y: "5%", w: "22%", h: "25%" },
        { x: "77%", y: "5%", w: "22%", h: "30%" },
      ];

      // For the close-up images, apply the same calculation
      if (productDimensions.dimensions.closeupImages?.length) {
        productDimensions.dimensions.closeupImages.forEach((img, idx) => {
          const closeUpImageSize = img.dimension; // Use the dimension of each close-up image
          const closeUpDimensions = calculateWidthAndXPosition(
            closeUpImageSize,
            22,
            25
          );
          const closeUpImagePosition = closeUpImagePositions[idx]; // Pre-defined positions from your array

          slide1.addImage({
            data: product.closeup_images_base64[idx],
            x: closeUpImagePosition.x,
            y: closeUpImagePosition.y,
            w: closeUpDimensions.w,
            h: closeUpDimensions.h,
            sizing: { type: "contain" }, // Maintain aspect ratio
          });
        });
      }

      const details = [
        { label: "Brand", value: product?.brand },
        { label: "Sub Brand", value: product?.sub_brand },
        { label: "Department", value: product?.department },
        { label: "Description", value: product?.description },
        { label: "Season", value: product?.season },
        { label: "Style", value: product?.product_style },
        { label: "Gender", value: product?.gender },
        { label: "Categories", value: product?.categories },
        { label: "Division", value: product?.division },
        { label: "Phase", value: product?.phase },
        { label: "Shipment Date", value: product?.shipment_date },
        { label: "Order Quantity", value: product?.order_quantity },
        { label: "Garment FOB (USD)", value: product?.garment_fob },
        { label: "Customer", value: product?.customer },
        { label: "Factory", value: product?.factory },
        { label: "Selected By", value: product?.selected_user },
        { label: "Comment", value: product?.comment },

      ];
      const fabricInfo = product?.fabric_info?.map((fabric, index) => ({
        [`Fabric ${index + 1} Info`]: [
          { label: "Fabric Reference No.", value: fabric?.fabric_ref },
          { label: "Content", value: fabric?.content },
          { label: "Construction", value: fabric?.construction },
          { label: "Fabric Mill/Supplier", value: fabric?.fabric_supplier },
          { label: "Shrinkage", value: fabric?.shrinkage },
          {
            label: "Weight",
            value: fabric?.weight
              ? `${fabric.weight} ${fabric.weight_unit}`
              : undefined,
          },
          {
            label: "Cuttable Width",
            value: fabric?.cw ? `${fabric.cw} ${fabric.cw_unit}` : undefined,
          },
          // { label: "Currency", value: fabric?.fabric_currency },
          {
            label: `Price (${fabric?.fabric_currency})`,
            value: fabric?.fabric_price ? `${fabric.fabric_price}` : undefined,
          },
          { label: "Yardage", value: fabric?.yardage },
        ]
          // Filter out any fields where the value is undefined or null
          .filter(({ value }) => value !== undefined && value !== null),
      }));
      console.log(details);
      console.log(fabricInfo);
      const approximateLineCount = (text, fontSize, maxWidth) => {
        const averageCharWidth = fontSize * 0.8;
        const maxCharsPerLine = Math.max((maxWidth * 96) / averageCharWidth); // Minimum 1 char per line
        console.log(
          text,
          text.length,
          maxCharsPerLine,
          Math.ceil(text.length / maxCharsPerLine)
        );
        return Math.ceil(text.length / maxCharsPerLine);
      };
      let yPosition = 0.1;
      // let xPositions = [5, 7.5]; // Positions for the left and right columns
      let xPositions = [5.2, 6.7, 8.2]; // Positions for the left, middle, and right columns
      let columnIndex = 0;
      if (!productDimensions.dimensions.closeupImages?.length) {
        yPosition = 0.1;
      } else {
        yPosition = 1.7;
      }
      const rowHeights = [];
      // Filter out details with undefined values
      const filteredDetails = details.filter(
        (detail) => detail.value !== undefined
      );
      // let rowHeights = []; // Temporary storage for row heights
      const labelValueGap = 0.1; // Fixed gap between label and value
      const valueLineGap = 0.1; // Fixed gap between value and the line

      filteredDetails.forEach((detail, index) => {
        const x = xPositions[columnIndex];
        const maxWidth = 1.7;
        const fontSize = 8;

        // Calculate line counts
        const labelLines = approximateLineCount(
          detail.label,
          fontSize,
          maxWidth
        );
        const valueText =
          detail.label === "Shipment Date"
            ? convertDate(detail.value)
            : detail.value;
        const valueLines = approximateLineCount(valueText, fontSize, maxWidth);

        // Convert lines to height
        const labelHeight = fontSize * labelLines * 0.01;
        const valueHeight = fontSize * valueLines * 0.01;

        // Calculate the current row index
        const rowIndex = Math.floor(index / 3);
        rowHeights[rowIndex] = rowHeights[rowIndex] || {
          maxLabelHeight: 0,
          maxValueHeight: 0,
        };

        // Update maxLabelHeight and maxValueHeight for the current row
        rowHeights[rowIndex].maxLabelHeight = Math.max(
          rowHeights[rowIndex].maxLabelHeight,
          labelHeight
        );
        rowHeights[rowIndex].maxValueHeight = Math.max(
          rowHeights[rowIndex].maxValueHeight,
          valueHeight
        );

        // Move to the next column
        columnIndex = (columnIndex + 1) % 3;

        // At the end of each row (or last item), apply max row heights and reset column index
        if (columnIndex === 0 || index === filteredDetails.length - 1) {
          // Now apply the max heights to each item in the current row
          const startRowIndex = rowIndex * 3;
          for (let col = 0; col < 3; col++) {
            const cellIndex = startRowIndex + col;
            if (cellIndex < filteredDetails.length) {
              const cellDetail = filteredDetails[cellIndex];
              const cellX = xPositions[col];

              // Use row max heights for label and value
              slide1.addText(cellDetail.label, {
                x: cellX,
                y: yPosition,
                w: maxWidth,
                h: rowHeights[rowIndex].maxLabelHeight,
                fontSize: 7,
                valign: "middle",
              });

              slide1.addText(
                cellDetail.label === "Shipment Date"
                  ? convertDate(cellDetail?.value)
                  : cellDetail?.value,
                {
                  x: cellX,
                  y:
                    yPosition +
                    rowHeights[rowIndex].maxLabelHeight +
                    labelValueGap,
                  w: maxWidth,
                  h: rowHeights[rowIndex].maxValueHeight,
                  fontSize: 8,
                  bold: true,
                  valign: "middle",
                }
              );

              // Add separator line below the field
              slide1.addShape(pptx.shapes.LINE, {
                x: cellX + 0.1,
                y:
                  yPosition +
                  rowHeights[rowIndex].maxLabelHeight +
                  rowHeights[rowIndex].maxValueHeight +
                  labelValueGap +
                  valueLineGap,
                w: 1.3,
                h: 0.0,
                line: { color: "C6C6D0", width: 0.2, dashType: "dash" },
              });
            }
          }

          // Update yPosition after completing the row
          if (index < filteredDetails.length - 1) {
            yPosition +=
              rowHeights[rowIndex].maxLabelHeight +
              rowHeights[rowIndex].maxValueHeight +
              labelValueGap +
              valueLineGap +
              0.1;
          }

          // Reset columnIndex for the next row
          columnIndex = 0;
        }
      });

      // Add a gap after details

      // Reset columnIndex before rendering fabric_info
      columnIndex = 0;
      yPosition += 0.3;
      fabricInfo?.forEach((fabricObj, fabricIndex) => {
        // Adjusted Y position for the first fabric
        const fabricYPosition = yPosition;

        // Get the fabric title and details (label-value pairs)
        const fabricTitle = Object.keys(fabricObj)[0]; // e.g. "Fabric 1 Info", "Fabric 2 Info"
        const fabricDetails = fabricObj[fabricTitle]; // Array of label-value pairs
        yPosition = fabricYPosition + 0.05; // Start just below the "Phase" text

        // Add the fabric title
        slide1.addText(fabricTitle, {
          x: xPositions[0], // Start from the first column (left)
          y: fabricYPosition, // Continue from the last position of 'details' with the added gap
          w: 5,
          h: 0.5,
          color: "26408A",
          fontSize: 8,
          bold: true,
        });

        // Increment 'yPosition' to move to the next line after the title
        yPosition += 0.2;

        // Reset columnIndex to 0 for each fabric to ensure new fabric details start from the first column
        columnIndex = 0;

        // Iterate over the fabric details and display them in 3 columns
        fabricDetails.forEach((fabricDetail, detailIndex) => {
          const x = xPositions[columnIndex]; // Switch between the three columns
          slide1.addText(fabricDetail.label, {
            x, // Ensure label starts at the current column
            y: yPosition,
            w: 1.5, // Adjust width to fit 3 columns
            h: 0.5,
            fontSize: 7,
            valign: "middle",
          });
          slide1.addText(fabricDetail.value, {
            x, // Ensure value starts at the current column
            y: yPosition + 0.2,
            w: 1.3,
            h: 0.5,
            fontSize: 8,
            bold: true,
            valign: "middle",
          });

          // Add a line below each value
          slide1.addShape(pptx.shapes.LINE, {
            x: x + 0.1,
            y: yPosition + 0.6,
            w: 1.3,
            h: 0.0,
            line: { color: "C6C6D0", width: 0.2, dashType: "dash", h: 0.5 },
          });

          // Move to the next column
          columnIndex = (columnIndex + 1) % 3;

          // Only add space when transitioning between rows within the same fabric
          if (columnIndex === 0 && detailIndex !== fabricDetails.length - 1) {
            yPosition += 0.5; // Move to the next row if not the last detail
          }
        });

        // After each fabric's info, add extra spacing
        yPosition += 0.6;
      });

      // Slide 2: Back image and closeup images
    });

    const slide2 = pptx.addSlide();

    slide2.addImage({
      data: ppt_last_page,
      x: "0%",
      y: "0%",
      w: "100%",
      h: "100%",
    });
    slide2.addImage({
      data: ppt_text_label,
      x: "25%",
      y: "45%",
      w: "50%",
      h: "20%",
      sizing: { type: "contain" },
    });
    slide2.addImage({
      data: ppt_plus_icon,
      x: "90%",
      y: "80%",
      w: "5%",
      h: "8%",
      sizing: { type: "contain" },
    });
    slide2.addImage({
      data: ppt_hollow,
      x: "33%",
      y: "0%",
      w: "33%",
      h: "33%",
      sizing: { type: "contain" },
    });

    console.log(selectedDocType);
    if (selectedDocType === "ppt") {
      pptx.writeFile({ fileName: "presentation.pptx", compression: true });
    } else if (selectedDocType === "pdf") {
      const pptBase64 = await pptx.write("base64");
      console.log(pptBase64);

      // Send the base64 string to the backend for conversion
      const sessionId = uuidv4();

      // Send the base64 string in chunks
      const success = await sendInChunks(pptBase64, sessionId);
      if (success) {
        console.log("PPT successfully converted to PDF");
        toast.update(toastId, {
          render: "Download complete!",
          type: "success",
          autoClose: 3000,
        });
      } else {
        console.error("Error converting PPT to PDF");
        toast.update(toastId, {
          render: "Error occurred while downloading!",
          type: "error",
          autoClose: 3000,
        });
      }
    }
    setGenerate(false); // Reset the trigger state
  }, [selectedDocType, generate]);

  const sendInChunks = async (base64String, sessionId) => {
    try {
      const totalChunks = Math.ceil(base64String.length / CHUNK_SIZE);

      for (let i = 0; i < totalChunks; i++) {
        const chunk = base64String.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
        await axios.post(
          `${server}/product/upload-chunk`,
          { chunk, index: i, totalChunks, sessionId },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
      }

      // Signal the server to start conversion
      const { data } = await axios.post(
        `${server}/product/convert-to-pdf`,
        { sessionId },
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer",
          withCredentials: true,
        }
      );

      // Handle the received PDF data
      const blob = new Blob([data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "converted.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
      return true;
    } catch (error) {
      console.error("Error converting PPT to PDF:", error);
      return false;
    }
  };
  console.log(pptBase64String);
  useEffect(() => {
    handleGeneratePpt();
  }, [generate, handleGeneratePpt]);
  const handleBackButtonClick = (event) => {
    localStorage.setItem("activeItem", "");
    setActiveItem("");
    setDemoSelected(false);
    event.preventDefault();
    navigate("/dashboard");
  };

  const handleSlideClick = (productIndex, slideIndex) => {
    try {
      setLastSlideVisible(false);
      setFirstSlideVisible(false);
      setSelectedProductIndex(productIndex);
      setSelectedSlideIndex(slideIndex);
    } catch (error) {
      console.log(error);
      return;
    }
  };
  const handlePptButtonClick = () => {
    setSelectedDocType("ppt");
    setGenerate(true); // Trigger the generation
  };

  const handlePdfButtonClick = () => {
    setSelectedDocType("pdf");
    setGenerate(true); // Trigger the generation
  };
  const handleExcelButtonClick = () => {
    if (selectedProductIds.length > 0) {
      downloadExcelFile(selectedProductIds, checkedAttributes);
    }
  };
  console.log(selectedFilteredProducts);
  return (
    <div className="product-preview-container">
      <div className="product-preview-header">
        <div className="back-button-container">
          <button className="ppt-back-button" onClick={handleBackButtonClick}>
          <img src={backArrow} width="24px"/>
          </button>
          <span className="ppt-title-div">Presentation</span>
        </div>
        <div className="dropdown">
          <button onClick={toggleDropdown} className="dropbtn">
            Download
            <RiArrowDropDownLine />
          </button>
          {dropdownOpen && (
            <div className="dropdown-content-ppt">
              {/* Download PPT button */}
              <button
                className="dropdown-content-ppt-btn"
                onClick={handlePptButtonClick}
              >
                PPT
              </button>
              {/* Download PDF button */}
              <button
                className="dropdown-content-ppt-btn"
                onClick={handlePdfButtonClick}
              >
                PDF
              </button>
              <button
                className="dropdown-content-ppt-btn"
                onClick={handleExcelButtonClick}
              >
                Excel
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="products-ppt-preview-view-container">
        <div className="preview-products-container">
          <div
            className={`first-slide ${firstSlideVisible ? "active-slide" : ""}`}
            onClick={() => {
              setFirstSlideVisible(true);
              setLastSlideVisible(false);
            }}
          >
            <img className="pearl-text-img" src={pptPearlGlobalText} />
            <img className="ppt-plus-icon" src={plusIcon} />
            <img className="ppt-hollow-icon" src={pptHollow} />
            <span className="ppt-corner-text preview">{pptDate}</span>
          </div>
          {selectedFilteredProducts.map((product, productIndex) => (
            <div
              style={{ gap: "1rem", display: "flex", flexDirection: "column" }}
              key={productIndex}
            >
              <ProductPreview
                product={product}
                onSlideClick={(slideIndex) =>
                  handleSlideClick(productIndex, slideIndex)
                }
                activeSlide={
                  productIndex === selectedProductIndex
                    ? selectedSlideIndex
                    : null
                }
              />
            </div>
          ))}
          <div
            className={`last-slide ${lastSlideVisible ? "active-slide" : ""}`}
            onClick={() => {
              setLastSlideVisible(true);
              setFirstSlideVisible(false);
            }}
          >
            <img className="pearl-text-img" src={pptPearlGlobalText} />
            <img className="ppt-plus-icon" src={plusIcon} />
            <img className="ppt-hollow-icon" src={pptHollow} />
          </div>
        </div>
        <div className="product-main-slide-container">
          <div
            ref={viewRef}
            style={{ flex: 1, padding: "12px", width: "100%", height: "100%" }}
          >
            {firstSlideVisible && (
              <div className="slide">
                <div className={`first-slide view`}>
                  <img className="pearl-text-img" src={pptPearlGlobalText} />
                  <img className="ppt-plus-icon" src={plusIcon} />
                  <img className="ppt-hollow-icon" src={pptHollow} />
                  <span className="ppt-corner-text generated-date other-slides">{`Ppt Generated on${dayjs().format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}`}</span>
                </div>
              </div>
            )}
            {selectedProductIndex !== null &&
              !lastSlideVisible &&
              !firstSlideVisible && (
                <div className="slide">
                  <ProductPreview
                    product={selectedFilteredProducts[selectedProductIndex]}
                    activeSlide={selectedSlideIndex}
                    isMainDisplay
                    products={selectedFilteredProducts}
                  />
                </div>
              )}
            {lastSlideVisible && (
              <div className="slide">
                <div className={`last-slide view`}>
                  <img className="pearl-text-img" src={pptPearlGlobalText} />
                  <img className="ppt-plus-icon" src={plusIcon} />
                  <img className="ppt-hollow-icon" src={pptHollow} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  selectedFilteredProductsData: state.selectedFilteredProducts.filteredProducts,
  selectedCheckedAttributes: state.selectedCheckedAttributes.checkedAttributes,
});

const mapDispatchToProps = {
  setSelectedFilteredProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(PptGenerator);
// old ppt code with 2 page format and first apge included
// const handleGeneratePpt = useCallback(async () => {
//   if (!generate) return;
//   const pptx = new PptxGenJS();
//   const slide0 = pptx.addSlide();
//   slide0.addImage({
//     data: pptFirstPage,
//     x: "0%",
//     y: "0%",
//     w: "100%",
//     h: "100%",
//   });
//   selectedFilteredProducts.forEach((product) => {
//     // Slide 1: Front image and details
//     const slide1 = pptx.addSlide();
//     // slide1.addText('Fabric Info', { x: 0.5, y: 0.2, w: 9, h: 0.5, fontSize: 24, bold: true, align: 'center' });
//     slide1.addImage({
//       data: pptLabel_base64,
//       x: "0%",
//       y: "0%",
//       w: 3,
//       h: "100%",
//     });
//     slide1.addShape(pptx.shapes.RECTANGLE, {
//       x: "15%",
//       y: "10%",
//       w: "24%",
//       h: "80%",
//       line: { color: "CDE5FF", width: 1 }, // Black border with width 2
//       fill: { color: "FFFFFF" }, // White fill
//     });
//     slide1.addImage({
//       data: product.front_image_base64,
//       x: "15%",
//       y: "10%",
//        w: "24%", h: "80%",
//       sizing: {type:"contain" ,x: "15%", y: "20%", },
//     });
//     const details = [
//       { label: "Description", value: product.description },
//       { label: "Season", value: product.season },
//       { label: "Style", value: product.product_style },
//       { label: "Gender", value: product.gender },
//       { label: "Categories", value: product.categories },
//       { label: "Fabric Reference No.", value: product.fabric_ref },
//       { label: "Content", value: product.content },
//       { label: "Construction", value: product.construction },
//       { label: "Fabric Mill/Supplier", value: product.fabric_supplier },
//       { label: "Shrinkage", value: product.shrinkage },
//       { label: "Weight", value: product.weight },
//       { label: "Cuttable Width", value: product.cw },
//       { label: "Fabric Price ($)", value: product.fabric_price },
//       { label: "Fabric FOB (USD)", value: product.fob },
//     ];
//     const phaseDetails = [
//       { label: "Phase", value: product.phase },
//       { label: "Shipment Date", value: product.shipment_date },
//       { label: "Order Quantity", value: product.order_quantity },
//       { label: "Garment FOB (USD)", value: product.garment_fob },
//     ];

//     // Left and right columns
//     slide1.addText("Fabric Info", {
//       x: 4.5,
//       y: 0.3,
//       fontSize: 12,
//       bold: true,
//       color: "26408A",
//     });

//     let yPosition = 0.4;
//     const xPositions = [4.5, 7]; // Positions for the left and right columns
//     let columnIndex = 0;

//     // Process the main details
//     details.forEach((detail) => {
//       if (detail.value) {
//         const x = xPositions[columnIndex];
//         slide1.addText(detail.label, {
//           x,
//           y: yPosition,
//           w: 4,
//           h: 0.5,
//           fontSize: 10,
//         });
//         slide1.addText(detail.value, {
//           x,
//           y: yPosition + 0.2,
//           w: 4,
//           h: 0.5,
//           fontSize: 10,
//           bold: true,
//         });

//         // Add a line below each value
//         slide1.addShape(pptx.shapes.LINE, {
//           x: x,
//           y: yPosition + 0.6,
//           w: 2,
//           h: 0,
//           line: { color: "C6C6D0", width: 1 },
//         });

//         // Move to the next position
//         columnIndex = (columnIndex + 1) % 2;
//         if (columnIndex === 0) {
//           yPosition += 0.5;
//         }
//       }
//     });

//     // Adding 'Phase' text at the correct position if any phase details exist
//     if (phaseDetails.some((detail) => detail.value)) {
//       let columnIndex = 0;
//       const phaseYPosition = yPosition + 0.4;
//       slide1.addText("Phase", {
//         x: 4.5,
//         y: phaseYPosition,
//         fontSize: 12,
//         bold: true,
//         color: "26408A",
//       });

//       yPosition = phaseYPosition + 0.05; // Update yPosition to start just below the "Phase" text

//       // Process the phase details
//       phaseDetails.forEach((detail) => {
//         if (detail.value) {
//           const x = xPositions[columnIndex];
//           slide1.addText(detail.label, {
//             x,
//             y: yPosition,
//             w: 4,
//             h: 0.5,
//             fontSize: 10,
//             color: "45464F",
//           });
//           if (detail.label === "Shipment Date") {
//             slide1.addText(convertDate(detail.value), {
//               x,
//               y: yPosition + 0.2,
//               w: 4,
//               h: 0.5,
//               fontSize: 10,
//               bold: true,
//               color: "1A1B21",
//             });
//           } else {
//             slide1.addText(detail.value, {
//               x,
//               y: yPosition + 0.2,
//               w: 4,
//               h: 0.5,
//               fontSize: 10,
//               bold: true,
//               color: "1A1B21",
//             });
//           }
//           // Add a line below each value
//           slide1.addShape(pptx.shapes.LINE, {
//             x: x,
//             y: yPosition + 0.6,
//             w: 2,
//             h: 0,
//             line: { color: "C6C6D0", width: 1 },
//           });

//           // Move to the next position
//           columnIndex = (columnIndex + 1) % 2;
//           if (columnIndex === 0) {
//             yPosition += 0.5;
//           }
//         }
//       });
//     }

//     // Slide 2: Back image and closeup images
//     const slide2 = pptx.addSlide();

//     const margin = "1%";
//     const totalImages = product.closeup_images_base64
//       ? product.closeup_images_base64.length
//       : 0;

//     if (totalImages === 0) {
//       // No close-up images
//       slide2.addShape(pptx.shapes.RECTANGLE, {
//         x: "1%",
//         y: margin,
//         w: "98%",
//         h: "98%",
//         line: { color: "CDE5FF", width: 1 }, // Border color and width
//         fill: { color: "EFEFEF" }, // Fill color
//       });
//       slide2.addImage({
//         data: product.back_image_base64,
//         x: "36%",
//         y: margin,
//         w: "24%", h: "98%",
//         sizing: { type: "contain", x: "1%", y: margin,  }, // Maintain aspect ratio
//       });
//     } else if (totalImages === 1) {
//       // One close-up image
//       const closeupPos = { x: "1%", y: margin, w: "48%", h: "98%" };
//       slide2.addShape(pptx.shapes.RECTANGLE, {
//         x: closeupPos.x,
//         y: closeupPos.y,
//         w: closeupPos.w,
//         h: closeupPos.h,
//         line: { color: "CDE5FF", width: 1 }, // Border color and width
//         fill: { color: "EFEFEF" }, // Fill color
//       });

//       slide2.addImage({
//         data: product.closeup_images_base64[0],
//         x: closeupPos.x,
//         y: "24%",
//         h:"50%",w:"48%",
//         sizing: { type: "contain",x: closeupPos.x,y: closeupPos.y }, // Maintain aspect ratio
//       });
//       slide2.addShape(pptx.shapes.RECTANGLE, {
//         x: "51%",
//         y: margin,
//         w: "48%",
//         h: "98%",
//         line: { color: "CDE5FF", width: 1 }, // Border color and width
//         fill: { color: "EFEFEF" }, // Fill color
//       });
//       slide2.addImage({
//         data: product.back_image_base64,
//         x: "62%",
//         y: margin,w: "24%", h: "98%",
//         sizing: { type: "contain", x: "51%", y: margin,  }, // Maintain aspect ratio
//       });
//     } else if (totalImages === 2) {
//       // Two close-up images
//       const closeupPositions = [
//         { x: "1%", y: margin, w: "32%", h: "98%" },
//         { x: "34%", y: margin, w: "32%", h: "98%" },
//       ];
//       const closeUpImagePositions=[
//         { x: "1%", y: "24%", w: "32%", h: "45%" },
//         { x: "34%", y: "24%", w: "32%", h: "45%" },
//       ]
//       closeupPositions.forEach((pos, idx) => {
//         slide2.addShape(pptx.shapes.RECTANGLE, {
//           x: pos.x,
//           y: pos.y,
//           w: pos.w,
//           h: pos.h,
//           line: { color: "CDE5FF", width: 1 }, // Border color and width
//           fill: { color: "EFEFEF" }, // Fill color
//         });
//       });
//       closeUpImagePositions.forEach((pos, idx) => {

//         slide2.addImage({
//           data: product.closeup_images_base64[idx],
//           x: pos.x,
//           y: pos.y,
//           w: "32%", h: pos.h,
//           sizing: { type: "contain", x: pos.x, y: pos.y }, // Maintain aspect ratio
//         });
//       });

//       slide2.addShape(pptx.shapes.RECTANGLE, {
//         x: "67%",
//         y: margin,
//         w: "32%",
//         h: "98%",
//         line: { color: "CDE5FF", width: 1 }, // Border color and width
//         fill: { color: "EFEFEF" }, // Fill color
//       });
//       slide2.addImage({
//         data: product.back_image_base64,
//         x: "67%",
//         y: margin,
//         w: "24%", h: "98%",
//         sizing: { type: "contain", x: "51%", y: margin }, // Maintain aspect ratio
//       });
//     } else {
//       // Three close-up images
//       const closeupPositions = [
//         { x: "1%", y: margin, w: "32%", h: "98%" },
//         { x: "34%", y: margin, w: "32%", h: "48%" },
//         { x: "34%", y: "51%", w: "32%", h: "48%" },
//       ];
//       const closeupImagePositions = [
//         { x: "1%", y: "30%", w: "32%", h: "38%" },
//         { x: "34%", y: "6%", w: "32%", h: "38%" },
//         { x: "34%", y: "56%", w: "32%", h: "38%" },
//       ];

//       closeupPositions.forEach((pos, idx) => {
//         slide2.addShape(pptx.shapes.RECTANGLE, {
//           x: pos.x,
//           y: pos.y,
//           w: pos.w,
//           h: pos.h,
//           line: { color: "CDE5FF", width: 1 }, // Border color and width
//           fill: { color: "EFEFEF" }, // Fill color
//         });
//       });
//       closeupImagePositions.forEach((pos, idx) => {
//         slide2.addImage({
//           data: product.closeup_images_base64[idx],
//           x: pos.x,
//           y: pos.y,
//           w: pos.w, h: pos.h,
//           sizing: { type: "contain", x: pos.x, y: pos.y  }, // Maintain aspect ratio
//         });
//       });

//       slide2.addShape(pptx.shapes.RECTANGLE, {
//         x: "67%",
//         y: margin,
//         w: "32%",
//         h: "98%",
//         line: { color: "CDE5FF", width: 1 }, // Border color and width
//         fill: { color: "EFEFEF" }, // Fill color
//       });
//       slide2.addImage({
//         data: product.back_image_base64,
//         x: "69%",
//         y: margin,
//         w: "28%", h: "98%",
//         sizing: { type: "contain", x: "69%", y: margin }, // Maintain aspect ratio
//       });
//     }
//     if (product.best_seller) {
//       slide1.addImage({
//         data: bestSellerBase64,
//         x: "88%",
//         y: "-1%",
//         sizing: { type: "contain", x: "88%", y: "-%", w: "5%", h: "12%" },
//       });
//       slide2.addImage({
//         data: bestSellerBase64,
//         x: "88%",
//         y: "-1%",
//         sizing: { type: "contain", x: "88%", y: "-1%", w: "5%", h: "12%" },
//       });
//     }
//   });
//   console.log(selectedDocType);
//   if (selectedDocType === "ppt") {
//     pptx.writeFile({ fileName: "presentation.pptx" });
//   } else if (selectedDocType === "pdf") {
//     const pptBase64 = await pptx.write("base64");
//     console.log(pptBase64);

//     // Send the base64 string to the backend for conversion
//     const sessionId = uuidv4();

//     // Send the base64 string in chunks
//     const success = await sendInChunks(pptBase64, sessionId);
//     if (success) {
//       console.log("PPT successfully converted to PDF");
//     } else {
//       console.error("Error converting PPT to PDF");
//     }
//   }
//   setGenerate(false); // Reset the trigger state
// }, [selectedDocType, generate]);
