import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  addProduct,
  addNewDropdownValue,
  getProductsDropDownValues,
  getBrandDetails,
} from "../../redux/action/addProduct.js";
import arrow from "./icons/arrow.svg";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import downArrow from "./icons/down-arrow.png";
import addfile from "./icons/addfiles.svg";
import folder from "./icons/folder.svg";
import pdf from "./icons/pdf.svg";
import { MdClose } from "react-icons/md";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { getUserDetailsFromLocalStorage } from "../LocalStorage.js";
import { ExpandMore } from "@mui/icons-material";
import { Toaster, toast } from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import exit from "../../images/icons/exit.svg";
import { useParams } from "react-router-dom";
import { backend_url } from "../../BaseUrl.js";
import CircularProgress from "@mui/material/CircularProgress";

const SelectIcon = () => (
  <img
    src={downArrow}
    alt="down arrow"
    style={{ width: 12, height: 12, marginRight: "10px" }}
  />
);

const EditBrand = ({
  addNewSeason,
  getProductsDropDownValues,
  addNewDropdownValue,
  dropdownValues,
  addNewBrand,
  addNewDepartment,
  getBrandDetails,
  brandDetails,
}) => {
  const [formData, setFormData] = useState({
    brand: "",
    categories: "",
    department: "",
    season: "",
    documents: [],
    docFolders: [],
    // userId: getUserDetailsFromLocalStorage()?.user?._id,
  });
  const [currentField, setCurrentField] = useState("");

  const [initialData, setInitialData] = useState({
    brand: "",
    categories: "",
    department: "",
    season: "",
    documents: [],
    docFolders: [],
  });
  const [open, setOpen] = useState({
    brand: false,
    department: false,
    season: false,
    categories: false,
  });
  const [inputMode, setInputMode] = useState(false);

  const [brandInputMode, setBrandInputMode] = useState(false);
  const [departmentInputMode, setDepartmentInputMode] = useState(false);
  const [seasonInputMode, setSeasonInputMode] = useState(false);

  const [newBrand, setNewBrand] = useState("");
  const [newDepartment, setNewDepartment] = useState("");
  const [newSeason, setNewSeason] = useState("");
  const [trend, setTrend] = useState("");
  const navigate = useNavigate();
  const [openExit, setOpenExit] = useState(false);
  const { id } = useParams();
  const [documentNames, setDocumentNames] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [folders, setFolders] = useState([]);

  const [awsFolders, setAwsFolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prevFolder, setPrevFolder] = useState([]);
  const [newItem, setNewItem] = useState({
    brand: "",
    season: "",
    department: "",
    categories: "",
  });
  console.log("prevFolder", prevFolder);

  useEffect(() => {
    getProductsDropDownValues();

    const storedTrend = localStorage.getItem("trend");
    if (storedTrend) {
      setTrend(storedTrend);
    }

    if (id) {
      getBrandDetails(storedTrend, id);
    }
  }, [getBrandDetails, getProductsDropDownValues, id]);

  const extractDocNamesAndUpdateState = (urls) => {
    const extractedFileNames = urls.map((url) => {
      const parsedUrl = new URL(url);
      return parsedUrl.pathname.split("/").pop();
    });
    setDocumentNames(extractedFileNames); // Update files state with extracted file names
  };

  useEffect(() => {
    if (brandDetails) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        brand: brandDetails.brand,
        categories: brandDetails.categories,
        department: brandDetails.department,
        season: brandDetails.season,
      }));
      const filterdFiles = brandDetails.documents.filter((link) => {
        const urlParts = link.split("/");
        const folderName = urlParts[urlParts.length - 2];
        return folderName === brandDetails._id;
      });
      const nonFilteredFolder = brandDetails.documents.filter((link) => {
        const urlParts = link.split("/");
        const folderName = urlParts[urlParts.length - 2];
        return folderName !== brandDetails._id;
      });
      setFormData((prevFormData) => ({
        ...prevFormData,
        documents: filterdFiles.map((url) => ({
          name: url.substring(url.lastIndexOf("/") + 1),
        })),
      }));

      const nonFilteredFiles = brandDetails.documents
        .map((link) => {
          const urlParts = link.split("/");
          const folderIndex = urlParts.indexOf(brandDetails._id);

          if (folderIndex !== -1 && folderIndex < urlParts.length - 1) {
            return {
              name: urlParts.slice(folderIndex + 1).join("/"), // Extract everything after brandDetails._id
            };
          }
          return null;
        })
        .filter(Boolean); // Remove null values

      setPrevFolder(nonFilteredFiles);
      const folders = brandDetails.documents
        .map((link) => {
          const urlParts = link.split("/");
          const folderName = urlParts[urlParts.length - 2];
          return folderName;
        })
        .filter((folderName) => folderName !== brandDetails._id)
        .filter(
          (folderName, index, self) => self.indexOf(folderName) === index
        );
      setAwsFolders([...folders]);
      setDocuments(filterdFiles);

      setFolders(nonFilteredFolder);
      const documemntsArray = [...brandDetails.documents];
      extractDocNamesAndUpdateState(documemntsArray);
      setInitialData(formData);
    }
  }, [brandDetails]);

  useEffect(() => {
    if (brandInputMode) {
      const textField = document.getElementById("new-brand");
      if (textField) {
        textField.focus(); // Focus on the TextField if it exists
      }
    }
    if (seasonInputMode) {
      const textField = document.getElementById("new-season");
      if (textField) {
        textField.focus(); // Focus on the TextField if it exists
      }
    }
    if (departmentInputMode) {
      const textField = document.getElementById("new-department");
      if (textField) {
        textField.focus(); // Focus on the TextField if it exists
      }
    }
  }, [brandInputMode, seasonInputMode, departmentInputMode]);

  const handleAddBrand = async (e) => {
    e.preventDefault();
    if (newBrand.trim() !== "") {
      // setSeasons((prevSeasons) => [...prevSeasons, newItem.trim()]);
      await addNewBrand(newBrand.trim());
      setNewBrand("");
      setBrandInputMode(false);
      getProductsDropDownValues();
      setFormData((prevFormData) => ({
        ...prevFormData,
        brand: newBrand.trim(),
      }));
    }
  };

  const handleAddDepartment = async (e) => {
    e.preventDefault();
    if (newDepartment.trim() !== "") {
      // setSeasons((prevSeasons) => [...prevSeasons, newItem.trim()]);
      await addNewDepartment(newDepartment.trim());
      setNewDepartment("");
      setDepartmentInputMode(false);
      getProductsDropDownValues();
      setFormData((prevFormData) => ({
        ...prevFormData,
        department: newDepartment.trim(),
      }));
    }
  };
  const handleAddSeason = async (e) => {
    e.preventDefault();
    if (newSeason.trim() !== "") {
      // setSeasons((prevSeasons) => [...prevSeasons, newItem.trim()]);
      await addNewSeason(newSeason.trim());
      setNewSeason("");
      setSeasonInputMode(false);
      getProductsDropDownValues();
      setFormData((prevFormData) => ({
        ...prevFormData,
        season: newSeason.trim(),
      }));
    }
  };

  const handleRemoveAWSFolder = (folderName) => {
    setAwsFolders((prevFolders) =>
      prevFolders.filter((folder) => folder !== folderName)
    );

    // Extract relevant files before updating state
    const filteredFiles = prevFolder
      .filter((file) => file.name.includes(`/${folderName}/`))
      .map((file) => ({ name: file.name.split("/").slice(-2).join("/") }));

    console.log("filteredFiles", filteredFiles);

    // Update prevFolder by removing the files from the specified folder
    setPrevFolder((prevFiles) =>
      prevFiles.filter((file) => !file.name.includes(`/${folderName}/`))
    );

    return filteredFiles; // Return the filtered files if needed
  };

  const docInputRef = useRef(null);
  const folderInputRef = useRef(null);

  const handleDocButton = () => {
    docInputRef.current.click();
  };

  const handleDFolderButton = () => {
    folderInputRef.current.click();
  };
  useEffect(() => {
    // const userDetails = getUserDetailsFromLocalStorage();
    setFormData((prevFormData) => ({
      ...prevFormData,
      // userId: userDetails.user._id,
    }));
  }, []);
  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      console.error("Invalid event object:", e);
    }
  };

  console.log("formdata", formData);

  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />{" "}
              {/* Change color to #26408A */}
              <stop offset="50%" stopColor="#196D92" />{" "}
              {/* Change color to #196D92 */}
              <stop offset="100%" stopColor="#49883E" />{" "}
              {/* Change color to #49883E */}
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );
  if (loading) {
    return <GradientCircularProgress />;
  }
  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    const name = e.target.name;

    if (name === "documents") {
      const fileNames = documentNames.map((file) => file);

      const isFileNameExists = selectedFiles.some((file) =>
        fileNames.includes(file.name)
      );

      if (isFileNameExists) {
        e.target.value = null;
        toast.error("File with the same name already exists!");
        return;
      }

      setFormData((prevData) => ({
        ...prevData,
        documents: [...prevData.documents, ...selectedFiles],
      }));
      const newNames = selectedFiles.map((file) => file.name);
      setDocumentNames((prevData) => [...prevData, ...newNames]);
      e.target.value = null;
    } else if (name === "folder") {
      const fileStructure = {};

      // Loop through each file
      selectedFiles.forEach((file) => {
        const pathArray = file.webkitRelativePath.split("/");
        let keyName = pathArray.slice(0, -1).join("/"); // Join all except the last part (file name)
        const fileName = pathArray[pathArray.length - 1].replace(/\s/g, "_"); // Replace spaces with underscores in the file name
        const fileContent = file;

        // Replace spaces with underscores in the key name
        keyName = keyName.replace(/\s/g, "_");

        // Check if the folder name exists in awsFolders
        if (!awsFolders.includes(keyName)) {
          // Create or update the folder in the file structure
          fileStructure[keyName] = fileStructure[keyName] || {};
          fileStructure[keyName][fileName] = fileContent;
        } else {
          toast.error("Folder with the same name already exists!");
        }
      });

      console.log(fileStructure);

      // Update state with the file structure
      setFormData((prevData) => ({
        ...prevData,
        docFolders: {
          ...prevData.docFolders,
          ...fileStructure,
        },
      }));
      e.target.value = null;
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: selectedFiles[0],
      }));
    }
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        resolve(e.target.result);
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.readAsDataURL(file);
    });
  };
  console.log("documents", documents);
  const deleted_files = documents.filter((url) => {
    const fileName = url.substring(url.lastIndexOf("/") + 1);

    // Condition 1: File is not present in formData.documents
    const isFileDeleted = !formData.documents.some(
      (obj) => obj.name === fileName
    );
    // Both conditions must be met
    return isFileDeleted;
  });

  const deleted_folders = folders.filter((url) => {
    const pathAfterId = url.split(`/${id}/`)[1] || "";

    // Condition 2: Path is not present in prevFolder
    const isFolderDeleted = !prevFolder.some(
      (file) => file.name === pathAfterId
    );

    // Both conditions must be met
    return isFolderDeleted;
  });
  const deleted_documents = [
    ...new Set([...deleted_files, ...deleted_folders]),
  ];

  console.log("deleted_documents", deleted_documents);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let missingField = null; // Variable to store the first missing field

      // Check if any required field is empty
      // if (!formData.userId) {
      //   missingField = "User ID";
      // } else

      if (!formData.brand) {
        missingField = "Brand";
      } else if (!formData.season) {
        missingField = "Season";
      } else if (!formData.categories) {
        missingField = "Categories";
      } else if (!formData.department) {
        missingField = "Department";
      }

      //   else if (
      //     !(
      //       Object.keys(formData.docFolders).length > 0 ||
      //       formData.documents.length > 0
      //     )
      //   ) {
      //     missingField = "Document or Folders";
      //   }
      if (missingField) {
        toast.error(`${missingField} is required. Please fill in this field.`);
        return; // Exit function early if any required field is empty
      }

      const form = new FormData();
      form.append("product_id", id);
      form.append("brand", formData.brand);
      form.append("season", formData.season);
      form.append("categories", formData.categories);
      form.append("department", formData.department);

      // Append individual files

      if (
        formData.documents.filter((obj) => Object.keys(obj).length === 0)
          .length > 0
      ) {
        const updatedDocs = formData.documents.filter(
          (obj) => Object.keys(obj).length === 0
        );
        updatedDocs.forEach((image, index) => {
          form.append("documents", image);
        });
      }
      if (deleted_documents.length > 0) {
        form.append("deleted_documents", deleted_documents);
      }

      for (const folderName in formData.docFolders) {
        const folder = formData.docFolders[folderName];

        // Check if the folder is not empty
        if (Object.keys(folder).length > 0) {
          // Append each file in the folder to FormData
          for (const fileName in folder) {
            const fileContent = folder[fileName];
            form.append(folderName, fileContent);
          }
        }
      }
      setLoading(true); // Set loading to true before the API request

      if (trend === "Pearl") {
        try {
          await axios
            .put(`${backend_url}/product/editpearlbranddetails`, form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              console.log(response.data); // Log the response data for debugging
              toast.success("Pearl Trend Edited successfully!");
              setTimeout(() => {
                navigate(-1);
              }, 1000);
            });
        } catch (error) {
          console.error("Error creating product:", error);
          toast.error(
            `Error creating Trend: ${error.message}. Please try again.`
          );
        }
      } else if (trend === "Buyer") {
        try {
          await axios
            .put(`${backend_url}/product/editbuyerbranddetails`, form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              console.log(response.data); // Log the response data for debugging
              toast.success("Buyer Trend Edited successfully!");
              setTimeout(() => {
                navigate(-1);
              }, 1000);
            });
        } catch (error) {
          console.error("Error creating product:", error);
          toast.error(
            `Error creating Trend: ${error.message}. Please try again.`
          );
        }
      }
    } catch (error) {
      console.error("Error creating product:", error);
      toast.error(`Error creating Trend: ${error.message}. Please try again.`);
    } finally {
      setLoading(false); // Set loading to false after the API request completes
    }
  };

  // const appendFilesToFormData = (formData, folderObj, parentPath = "") => {
  //   for (const folderName in folderObj) {
  //     const folder = folderObj[folderName];

  //     if ("files" in folder) {
  //       const files = folder.files;
  //       for (const fileName in files) {
  //         const file = files[fileName];
  //         const filePath = parentPath
  //           ? `${parentPath}/${folderName}`
  //           : folderName;
  //         // Append each file to FormData with the full file path as key
  //         formData.append(`${filePath}/${fileName}`, file);
  //       }
  //     }

  //     if ("folders" in folder) {
  //       // Recursively handle subfolders
  //       const subFolderPath = parentPath
  //         ? `${parentPath}/${folderName}`
  //         : folderName;
  //       appendFilesToFormData(formData, folder.folders, subFolderPath);
  //     }
  //   }
  // };
  const handleNewItemChange = (e) => {
    const { value } = e.target;
    setNewItem((prev) => ({ ...prev, [currentField]: value }));
  };
  const handleAddItem = async (e) => {
    e.preventDefault();
    const trimmedItem = newItem[currentField]?.trim();

    if (trimmedItem) {
      const existingValues =
        dropdownValues?.data[0]?.[`product_${currentField}`];

      if (existingValues?.includes(trimmedItem)) {
        toast.error(`${trimmedItem} already exists in ${currentField}`);
        return;
      }

      try {
        await addNewDropdownValue({ field: currentField, value: trimmedItem });

        setNewItem((prev) => ({ ...prev, [currentField]: "" }));
        setInputMode(false);
        getProductsDropDownValues();

        setFormData((prevFormData) => ({
          ...prevFormData,
          [currentField]: trimmedItem,
        }));
      } catch (error) {
        console.error("Error adding item:", error);
        toast.error("Failed to add item");
      }
    }
  };
  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddItem(e);
    }
  };

  const handleBlur = () => {
    if (inputMode) {
      setInputMode(false);
    }
  };
  const handleRemoveFile = (indexToRemove) => {
    const fileNameToRemove = formData.documents[indexToRemove].name;

    setFormData((prevData) => ({
      ...prevData,
      documents: prevData.documents.filter(
        (_, index) => index !== indexToRemove
      ),
    }));

    // Update documentNames state
    setDocumentNames((prevNames) =>
      prevNames.filter((name) => name !== fileNameToRemove)
    );
  };

  const handleRemoveFolder = (folderNameToRemove) => {
    const updatedFormData = { ...formData };
    delete updatedFormData.docFolders[folderNameToRemove];
    setFormData(updatedFormData);
  };

  // const getLast7Uppercase = () => {
  //   const userId = formData.userId;
  //   if (userId && userId.length >= 7) {
  //     const last7Letters = userId.slice(-7).toUpperCase();
  //     return last7Letters;
  //   } else {
  //     return ""; // Return an empty string if userId is not available or doesn't have enough characters
  //   }
  // };
  const currentDate = new Date();

  const day = currentDate.getDate();
  const month = currentDate.toLocaleString("default", { month: "short" });
  const year = currentDate.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;

  console.log(formattedDate);

  const handleCancel = () => {
    if (JSON.stringify(initialData) === JSON.stringify(formData)) {
      navigate(-1);
    } else {
      setOpenExit(true);
    }
  };

  const handleClose = () => {
    setOpenExit(false);
  };

  const hanndleTrendBack = () => {
    navigate(-1);
  };

  const MenuProps = {
    PaperProps: {
      sx: {
        "& .MuiMenu-list": {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          maxHeight: "400px",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0px",
          },
        },
      },
    },
  };

  const handleDropdownOpen = (dropdown) => {
    setOpen((prevState) => ({
      ...prevState,
      [dropdown]: true,
    }));
  };

  const handleDropdownClose = (dropdown) => {
    setOpen((prevState) => ({
      ...prevState,
      [dropdown]: false,
    }));
  };

  const handleFieldClick = (field) => {
    setInputMode(true);
    setCurrentField(field); // Set the field that was clicked
  };
  return (
    <div>
      <Toaster />
      <Dialog
        open={openExit}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "502px",
            height: "333px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "32px 80px",
          },
        }}
        sx={{ "& .MuiDialogContent-root": { overflow: "hidden" } }}
      >
        <img src={exit} alt="" width={100} height={100} />
        <DialogTitle id="alert-dialog-title">
          {"Are You Sure You Want to Exit ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This {trend} Trend is not Added.
            <br /> You Can’t Undo This Action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 600,
              color: "#26408A",
              padding: "0.5rem 1rem",
              cursor: "pointer",
              width: "140px",
              height: "40px",
              borderRadius: "20px",
              border: "2px solid transparent",
              backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #26408A, #196D92, #49883E)`,
              backgroundOrigin: "padding-box, border-box",
              backgroundClip: "padding-box, border-box",
              marginRight: "24px",
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={hanndleTrendBack}
            autoFocus
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 600,
              padding: "0.5rem 1rem",
              cursor: "pointer",
              width: "140px",
              height: "40px",
              borderRadius: "20px",
              border: "2px solid transparent",
              background:
                "linear-gradient(to right, #26408A, #196D92,#49883E) padding-box,linear-gradient(to right, #26408A, #196D92,#49883E) border-box",
              color: "#fff",
              textTransform: "capitalize",
              // "&:hover": {
              //   backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #26408A, #196D92, #49883E)`,
              //   backgroundOrigin: "padding-box, border-box",
              //   backgroundClip: "padding-box, border-box",
              //   textTransform: "capitalize",
              //   background:
              //     "linear-gradient(to right, #26408A, #196D92,#49883E) padding-box,linear-gradient(to right, #26408A, #196D92,#49883E) border-box",
              //   color: "#fff",
              // },
            }}
          >
            Exit
          </Button>
        </DialogActions>
      </Dialog>
      <div className="addproduct-container">
        <div className="productdetails-functions">
          <div
            style={{ width: "300px", display: "flex", alignItems: "center" }}
          >
            <div onClick={handleCancel}>
              <img
                src={arrow}
                alt=""
                width={32}
                height={32}
                className="backicon"
              />
            </div>
            <div>
              <span className="back-navigate">Edit {trend} Trend</span>
            </div>
          </div>
        </div>
        <div className="buyertrend-addproduct-form">
          <div>
            {" "}
            <Box
              component="form"
              width="100%"
              alignItems="unset"
              noValidate
              autoComplete="off"
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  gap: "20px",
                  marginBottom: "15px",
                }}
              >
                <FormControl sx={{}} size="small">
                  {!inputMode || currentField !== "brand" ? (
                    <>
                      <InputLabel
                        htmlFor="demo-select-small"
                        sx={{
                          "&.MuiInputLabel-root": {
                            color: "rgba(0, 0, 0, 0.8)",
                          },
                        }}
                      >
                        Brand
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="brand"
                        label="Brand"
                        value={formData.brand}
                        onChange={handleChange}
                        open={open.brand}
                        onClose={() => handleDropdownClose("brand")}
                        onOpen={() => handleDropdownOpen("brand")}
                        IconComponent={ExpandMore}
                        MenuProps={MenuProps}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.8)",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(0, 0, 0, 0.8)",
                          },
                          "& .MuiSelect-icon": {
                            color: "#1A1B21",
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => handleFieldClick("brand")}
                          sx={{
                            borderBottom: "1px solid #C6C6D0",
                            backgroundColor: "#FFF !important",
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              width: "100%",
                              justifyContent: "center",
                              color: "#26408A",
                              fontWeight: "700",
                              fontSize: "16px",
                              height: "40px",
                              border: "none",
                              fontFamily:
                                "Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif",
                              backgroundColor: "#FFF",
                              textTransform: "none",
                            }}
                          >
                            Add New
                          </Button>
                        </MenuItem>
                        {dropdownValues?.data?.map((dropdown) =>
                          dropdown.brand.map((brand) => (
                            <MenuItem key={brand} value={brand}>
                              {brand}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </>
                  ) : (
                    <TextField
                      id="new-item-brand"
                      label="New Brand"
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.8)",
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(0, 0, 0, 0.8)",
                        },
                      }}
                      value={newItem.brand}
                      onChange={handleNewItemChange}
                      onKeyPress={handleInputKeyPress}
                      onBlur={handleBlur}
                    />
                  )}
                </FormControl>
                <FormControl sx={{}} size="small">
                  {!inputMode || currentField !== "categories" ? (
                    <>
                      <InputLabel
                        htmlFor="demo-select-small"
                        sx={{
                          "&.MuiInputLabel-root": {
                            color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                          },
                        }}
                      >
                        Categories
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="categories"
                        label="Categories"
                        value={formData.categories} // Use empty string for value
                        onChange={handleChange}
                        open={open.categories} // Open state for this specific dropdown
                        onClose={() => handleDropdownClose("categories")} // Close only this dropdown
                        onOpen={() => handleDropdownOpen("categories")} // Open only this dropdown
                        IconComponent={ExpandMore}
                        MenuProps={MenuProps}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.8)",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(0, 0, 0, 0.8)",
                          },
                          "& .MuiSelect-icon": {
                            // Select the icon specifically
                            color: "#1A1B21", // Set the color of the icon
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => handleFieldClick("categories")}
                          sx={{
                            borderBottom: "1px solid #C6C6D0",
                            backgroundColor: "#FFF !important",
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              width: "100%",
                              justifyContent: "center",
                              color: "#26408A",
                              fontWeight: "700",
                              fontSize: "16px",
                              height: "40px",
                              border: "none",
                              fontFamily:
                                "Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif",
                              backgroundColor: "#FFF",
                              textTransform: "none",
                            }}
                          >
                            Add New
                          </Button>
                        </MenuItem>
                        {dropdownValues?.data?.map((dropdown) =>
                          dropdown.categories.map((categories) => (
                            <MenuItem key={categories} value={categories}>
                              {categories}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </>
                  ) : (
                    <TextField
                      id="new-item-categories"
                      label="New Categories"
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                        },
                      }}
                      value={newItem.categories}
                      onChange={handleNewItemChange}
                      onKeyPress={handleInputKeyPress}
                      onBlur={handleBlur}
                    />
                  )}
                </FormControl>

                <FormControl sx={{}} size="small">
                  {!inputMode || currentField !== "department" ? (
                    <>
                      <InputLabel
                        htmlFor="demo-select-small"
                        sx={{
                          "&.MuiInputLabel-root": {
                            color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                          },
                        }}
                      >
                        Department
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="department"
                        label="Department"
                        value={formData.department} // Use empty string for value
                        onChange={handleChange}
                        open={open.department} // Open state for this specific dropdown
                        onClose={() => handleDropdownClose("department")} // Close only this dropdown
                        onOpen={() => handleDropdownOpen("department")} // Open only this dropdown
                        IconComponent={ExpandMore}
                        MenuProps={MenuProps}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.8)",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(0, 0, 0, 0.8)",
                          },
                          "& .MuiSelect-icon": {
                            // Select the icon specifically
                            color: "#1A1B21", // Set the color of the icon
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => handleFieldClick("department")}
                          sx={{
                            borderBottom: "1px solid #C6C6D0",
                            backgroundColor: "#FFF !important",
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              width: "100%",
                              justifyContent: "center",
                              color: "#26408A",
                              fontWeight: "700",
                              fontSize: "16px",
                              height: "40px",
                              border: "none",
                              fontFamily:
                                "Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif",
                              backgroundColor: "#FFF",
                              textTransform: "none",
                            }}
                          >
                            Add New
                          </Button>
                        </MenuItem>
                        {dropdownValues?.data?.map((dropdown) =>
                          dropdown.department.map((department) => (
                            <MenuItem key={department} value={department}>
                              {department}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </>
                  ) : (
                    <TextField
                      id="new-item-department"
                      label="New Department"
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                        },
                      }}
                      value={newItem.department}
                      onChange={handleNewItemChange}
                      onKeyPress={handleInputKeyPress}
                      onBlur={handleBlur}
                    />
                  )}
                </FormControl>

                <FormControl sx={{}} size="small">
                  {!inputMode || currentField !== "season" ? (
                    <>
                      <InputLabel
                        htmlFor="demo-select-small"
                        sx={{
                          "&.MuiInputLabel-root": {
                            color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                          },
                        }}
                      >
                        Season
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="season"
                        label="Season"
                        MenuProps={MenuProps}
                        value={formData.season} // Use empty string for value
                        onChange={handleChange}
                        open={open.season} // Open state for this specific dropdown
                        onClose={() => handleDropdownClose("season")} // Close only this dropdown
                        onOpen={() => handleDropdownOpen("season")} // Open only this dropdown
                        IconComponent={ExpandMore}
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(0, 0, 0, 0.8)",
                          },
                          "& .MuiInputLabel-root": {
                            color: "rgba(0, 0, 0, 0.8)",
                          },
                          "& .MuiSelect-icon": {
                            // Select the icon specifically
                            color: "#1A1B21", // Set the color of the icon
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => handleFieldClick("season")}
                          sx={{
                            borderBottom: "1px solid #C6C6D0",
                            backgroundColor: "#FFF !important",
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              width: "100%",
                              justifyContent: "center",
                              color: "#26408A",
                              fontWeight: "700",
                              fontSize: "16px",
                              height: "40px",
                              border: "none",
                              fontFamily:
                                "Segoe UI, SegoeUI, Helvetica Neue, Helvetica, Arial, sans-serif",
                              backgroundColor: "#FFF",
                              textTransform: "none",
                            }}
                          >
                            Add New
                          </Button>
                        </MenuItem>
                        {dropdownValues?.data?.map((dropdown) =>
                          dropdown.season.map((season) => (
                            <MenuItem key={season} value={season}>
                              {season}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </>
                  ) : (
                    <TextField
                      id="new-item-season"
                      label="New Season"
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.8)", // Change the border color to black
                        },
                        "& .MuiInputLabel-root": {
                          color: "rgba(0, 0, 0, 0.8)", // Change the label color to black
                        },
                      }}
                      value={newItem.season}
                      onChange={handleNewItemChange}
                      onKeyPress={handleInputKeyPress}
                      onBlur={handleBlur}
                    />
                  )}
                </FormControl>
              </div>
            </Box>
            <div className="buttons-align">
              <div className="doc-button">
                {" "}
                <input
                  type="file"
                  name="documents"
                  id="Input"
                  accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  multiple
                  ref={docInputRef}
                />
                <button
                  onClick={handleDocButton}
                  style={{
                    width: "100%",
                    height: "48px",
                    backgroundColor: "#e5f1ff",
                    border: "1px dashed #26408A",
                    borderRadius: "4px 0px 0px 4px",
                    borderRight: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#26408A",
                    fontSize: "16px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={addfile}
                    alt="addfile"
                    width={25}
                    style={{ marginRight: "8px" }}
                  />
                  <span>Attach Documents</span>
                </button>
              </div>
              <div className="folder-button">
                {" "}
                <input
                  type="file"
                  name="folder"
                  id="FolderInput"
                  accept="*/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  webkitdirectory=""
                  mozdirectory=""
                  multiple=""
                  ref={folderInputRef}
                />
                <button
                  onClick={handleDFolderButton}
                  style={{
                    width: "100%",
                    height: "48px",
                    backgroundColor: "#e5f1ff",
                    border: "1px dashed #26408A",
                    borderRadius: "0px 4px 4px 0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#26408A",
                    fontSize: "16px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={addfile}
                    alt="addfile"
                    width={25}
                    style={{ marginRight: "8px" }}
                  />
                  <span>Attach Folders</span>
                </button>
              </div>
            </div>
            <div className="upload-files">
              {awsFolders.map((folderName, index) => (
                <div key={index} className="email-file">
                  <div className="file-info">
                    <img src={folder} alt="folder" width={16} />
                    <p>{folderName}</p>
                  </div>
                  <div onClick={() => handleRemoveAWSFolder(folderName)}>
                    <MdClose />
                  </div>
                </div>
              ))}
              {Object.keys(formData.docFolders).map((folderName, index) => (
                <div key={index} className="email-file">
                  <div className="file-info">
                    <img src={folder} alt="folder" width={16} />
                    <p>{folderName}</p>
                  </div>
                  <div onClick={() => handleRemoveFolder(folderName)}>
                    <MdClose />
                  </div>
                </div>
              ))}

              {formData.documents.map((file, index) => (
                <div key={index} className="email-file">
                  <div className="file-info">
                    <img src={pdf} alt="file" width={16} />
                    <p>{file.name}</p>
                  </div>
                  <div onClick={() => handleRemoveFile(index)}>
                    <MdClose />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div class="buyerTrend-button-container">
            <button class="addproduct-button" onClick={handleCancel}>
              Cancel
            </button>
            <button class="addproduct-button-save" onClick={handleSubmit}>
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dropdownValues: state.dropdownValues.dropDownValues,
  loading: state.dropdownValues.loading,
  brandDetails: state.brandDetails.brandDetails,
});

const mapDispatchToProps = {
  addProduct,
  getProductsDropDownValues,
  addNewDropdownValue,
  getBrandDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBrand);

// <div style={{ gap: "10px", display: "flex" }}>
//   <div>
//     {" "}
//     <span style={{ fontSize: "12px", fontWeight: 500 }}>
//       UserID:{" "}
//     </span>
//     <span style={{ fontSize: "14px", fontWeight: 500 }}>
//       {getLast7Uppercase()}
//     </span>
//   </div>
//   <div>
//     {" "}
//     <span style={{ fontSize: "12px", fontWeight: 500 }}>Date: </span>
//     <span style={{ fontSize: "14px", fontWeight: 500 }}>
//       {formattedDate}
//     </span>
//   </div>
// </div>;
