import React, { useState } from "react";
import "./DropdownWithCheckbox.css";
import vector from "../../images/icons/Vector.svg";
import vectorUp from "../../images/icons/Vector-up.svg";

// Dropdown with checkbox

const DropdownWithCheckbox = ({
  options = [],
  name,
  selectedOptions,
  setSelectedOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCheckboxChange = (option) => {
    const isChecked = selectedOptions.some((item) => item === option);
    if (isChecked) {
      const updatedOptions = selectedOptions.filter((item) => item !== option);
      setSelectedOptions(updatedOptions);
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  return (
    <div className="dropdown">
      <button
        className="filter-dropdown-button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="side-filter-button">
          <div>
            <span className="filter-text-button">{name}</span>
          </div>
          <div>
            <img src={isOpen ? vectorUp : vector} alt="" />
          </div>
        </div>
      </button>
      {isOpen && (
        <div className="dropdown-content">
          {Array.isArray(options) &&
            options.map((option) => (
              <div className="filter-checkbox" key={option}>
                <div style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    value={option}
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                  />
                </div>
                <div>
                  <label className="filter-label">{option}</label>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default DropdownWithCheckbox;
