import React, { useState } from "react";
import pptLabel from "../images/ppt_label.png";
import "./PptGenerator.css";
import bestSellerIcon from "../images/icons/bestSeller.svg";
import convertDate from "../components/Helper/ConvertDate";
import firstSlideImg from "../images/first-slide.png";
import pptHollow from "../images/ppt-hollow.svg";
import plusIcon from "../images/plus-icon.svg";
import pearlTextIcon from "../images/pearl-logo.svg";
import { useEffect } from "react";
const ProductPreview = ({
  product,
  onSlideClick,
  activeSlide,
  isMainDisplay,
}) => {
  const details = product
    ? [
        { label: "Brand", value: product?.brand },
        { label: "Sub Brand", value: product?.sub_brand },
        { label: "Department", value: product?.department },
        { label: "Description", value: product?.description },
        { label: "Season", value: product?.season },
        { label: "Style", value: product?.product_style },
        { label: "Gender", value: product?.gender },
        { label: "Categories", value: product?.categories },
        { label: "Division", value: product?.division },
        { label: "Phase", value: product?.phase },
        { label: "Shipment Date", value: product?.shipment_date },
        { label: "Order Quantity", value: product?.order_quantity },
        { label: "Garment FOB (USD)", value: product?.garment_fob },
        { label: "Customer", value: product?.customer },
        { label: "Factory", value: product?.factory },
        { label: "Country", value: product?.country },
        { label: "Selected By", value: product?.selected_user },
        { label: "Comment", value: product?.comment },
      ]
    : [];

  // Dynamically append fabric_info details into a separate array
  const fabricInfo = product?.fabric_info?.length
    ? product.fabric_info.map((fabric, index) => ({
        [`Fabric ${index + 1} Info`]: [
          { label: "Fabric Reference No.", value: fabric?.fabric_ref },
          { label: "Content", value: fabric?.content },
          { label: "Construction", value: fabric?.construction },
          { label: "Fabric Mill/Supplier", value: fabric?.fabric_supplier },
          { label: "Shrinkage", value: fabric?.shrinkage },
          {
            label: "Weight",
            value: fabric?.weight
              ? `${fabric.weight} ${fabric.weight_unit}`
              : undefined,
          },
          {
            label: "Cuttable Width",
            value: fabric?.cw ? `${fabric.cw} ${fabric.cw_unit}` : undefined,
          },
          // { label: "Currency", value: fabric?.fabric_currency },
          {
            label: `Price (${fabric?.fabric_currency})`,
            value: fabric?.fabric_price ? `${fabric.fabric_price}` : undefined,
          },
          { label: "Yardage", value: fabric?.yardage },
        ]
          // Filter out any fields where the value is undefined or null
          .filter(({ value }) => value !== undefined && value !== null),
      }))
    : [];
  // console.log("activeSlide",activeSlide);
  console.log(details);
  console.log(fabricInfo);
  const slides = [
    <div
      className={`slide ${isMainDisplay ? "view" : "preview"} `}
      key="slide-1"
    >
      <img className="ppt-plus-icon" src={plusIcon} />
      {/* <img className="ppt-hollow-icon other-slides" src={pptHollow} /> */}
      <img className="pearl-text-icon other-slides" src={pearlTextIcon} />
      <span className="ppt-corner-text other-slides">
        THIS PRESENTATION IS CREATED BY PEARL AI PLATFORM
      </span>
      {product?.best_seller && (
        <img
          className={`bestseller-label-image ${
            isMainDisplay ? "view" : "preview"
          }`}
          src={bestSellerIcon}
          alt="Best Seller"
        />
      )}
      {/* <img
        className={`ppt-label-image ${isMainDisplay ? "view" : "preview"}`}
        src={pptLabel}
        alt="PPT Label"
      /> */}
      <div
        className={`front-image-container ${
          isMainDisplay ? "view" : "preview"
        }`}
      >
        <div
          className={`front-image-container-in ${isMainDisplay ? "view" : ""}`}
        >
          <img
            src={product?.front_image_base64}
            alt="Front"
            className={`ppt-front-image ${isMainDisplay ? "view" : ""}`}
            loading="lazy"
          />
        </div>
      </div>
      <div
        className={`front-image-container ${
          isMainDisplay ? "view" : "preview"
        }`}
      >
        <div
          className={`front-image-container-in ${isMainDisplay ? "view" : ""}`}
        >
          <img
            src={product?.back_image_base64}
            alt="Front"
            className={`ppt-front-image ${isMainDisplay ? "view" : ""}`}
            loading="lazy"
          />
        </div>
      </div>
      <div
        className={`product-details-container-out ${
          isMainDisplay ? "view" : ""
        }`}
      >
        <div className="closeup-images-container">
          {product?.closeup_images_base64 &&
            product?.closeup_images_base64.map((img, idx) => {
              if (product?.closeup_images_base64?.length > 2 && idx === 1) {
                // Wrap closeup-image-2 and closeup-image-3 in a separate div container
                return (
                  <div key="closeup-wrapper" className="closeup-wrapper">
                    <div
                      className={`closeup-image-container closeup-image-${
                        idx + 1
                      }-container ${isMainDisplay ? "view" : ""}`}
                    >
                      <img
                        src={img}
                        alt={`Closeup ${idx + 1}`}
                        className={`closeup-image closeup-image-${idx + 1} ${
                          isMainDisplay ? "view" : ""
                        }`}
                        loading="lazy"
                      />
                    </div>
                    <div
                      className={`closeup-image-container closeup-image-${
                        idx + 2
                      }-container ${isMainDisplay ? "view" : ""}`}
                    >
                      {product?.closeup_images_base64[idx + 1] && (
                        <img
                          src={product?.closeup_images_base64[idx + 1]}
                          alt={`Closeup ${idx + 2}`}
                          className={`closeup-image closeup-image-${idx + 2} ${
                            isMainDisplay ? "view" : ""
                          }`}
                          loading="lazy"
                        />
                      )}
                    </div>
                  </div>
                );
              } else if (
                idx === 1 &&
                product?.closeup_images_base64?.length === 2
              ) {
                // Special case for only two closeup images: second image should be full
                return (
                  <div
                    key={idx}
                    className={`closeup-image-container closeup-image-${
                      idx + 1
                    }-container ${isMainDisplay ? "view" : ""} full`}
                  >
                    <img
                      src={img}
                      alt={`Closeup ${idx + 1}`}
                      className={`closeup-image closeup-image-${idx + 1} ${
                        isMainDisplay ? "view" : ""
                      } full`}
                      loading="lazy"
                    />
                  </div>
                );
              } else if (idx !== 1 && idx !== 2) {
                // Return the images that are not in the separate div container
                const isFull =
                  product?.closeup_images_base64?.length === 1 ? "full" : "";
                return (
                  <div
                    key={idx}
                    className={`closeup-image-container closeup-image-${
                      idx + 1
                    }-container ${isMainDisplay ? "view" : ""} ${isFull}`}
                  >
                    <img
                      src={img}
                      alt={`Closeup ${idx + 1}`}
                      className={`closeup-image closeup-image-${idx + 1} ${
                        isMainDisplay ? "view" : ""
                      } ${isFull}`}
                      loading="lazy"
                    />
                  </div>
                );
              }
              return null; // Return null for skipped indexes to prevent rendering
            })}
        </div>
        <div
          className={`product-details-container ${isMainDisplay ? "view" : ""}`}
        >
          {details.map(
            (detail, index) =>
              detail.value && (
                <div
                  key={index}
                  className={`product-details-preview ${
                    isMainDisplay ? "view" : ""
                  }`}
                >
                  <div
                    className={`each-product-detail ${
                      isMainDisplay ? "view" : ""
                    }`}
                  >
                    {detail.label}
                  </div>
                  <span>
                    {detail.label === "Shipment Date"
                      ? convertDate(detail.value)
                      : detail.value}
                  </span>
                </div>
              )
          )}
        </div>
        <div
          className={`product-fabric-details-container ${
            isMainDisplay ? "view" : ""
          }`}
        >
          {fabricInfo.map((fabric, fabricIndex) => (
            <div key={fabricIndex} className="fabric-details-wrapper">
              {/* Fabric Info Title */}
              <h3
                className={`fabric-info-title ${isMainDisplay ? "view" : ""}`}
              >{`Fabric ${fabricIndex + 1} Info`}</h3>

              {/* Fabric Details */}
              <div
                className={`product-details-container ${
                  isMainDisplay ? "view" : ""
                }`}
              >
                {fabric[`Fabric ${fabricIndex + 1} Info`].map((detail, index) =>
                  detail.value ? (
                    <div
                      key={index}
                      className={`product-details-preview ${
                        isMainDisplay ? "view" : ""
                      }`}
                    >
                      <div
                        className={`each-product-detail ${
                          isMainDisplay ? "view" : ""
                        }`}
                      >
                        {detail.label}
                      </div>
                      <span>{detail.value}</span>
                    </div>
                  ) : null
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>,
    // <div
    //   className={`slide ${isMainDisplay ? "view" : "preview"} `}
    //   key="slide-2"
    // >
    //   {product?.best_seller && (
    //     <img
    //       className={`bestseller-label-image ${isMainDisplay ? "view" : ""}`}
    //       src={bestSellerIcon}
    //       alt="Best Seller"
    //       loading="lazy"
    //     />
    //   )}
    //   {/* <img className={`ppt-label-image ${isMainDisplay ? "view" : "" }`} src={pptLabel} alt="PPT Label" /> */}
    //   <div
    //     className={`product-images-container ${
    //       isMainDisplay ? "view" : "preview"
    //     }`}
    //   >
    //     {product?.closeup_images_base64 &&
    //       product?.closeup_images_base64.map((img, idx) => {
    //         if (product?.closeup_images_base64?.length > 2 && idx === 1) {
    //           // Wrap closeup-image-2 and closeup-image-3 in a separate div container
    //           return (
    //             <div key="closeup-wrapper" className="closeup-wrapper">
    //               <div
    //                 className={`closeup-image-container closeup-image-${
    //                   idx + 1
    //                 }-container ${isMainDisplay ? "view" : ""}`}
    //               >
    //                 <img
    //                   src={img}
    //                   alt={`Closeup ${idx + 1}`}
    //                   className={`closeup-image closeup-image-${idx + 1} ${
    //                     isMainDisplay ? "view" : ""
    //                   }`}
    //                   loading="lazy"
    //                 />
    //               </div>
    //               <div
    //                 className={`closeup-image-container closeup-image-${
    //                   idx + 2
    //                 }-container ${isMainDisplay ? "view" : ""}`}
    //               >
    //                 {product?.closeup_images_base64[idx + 1] && (
    //                   <img
    //                     src={product?.closeup_images_base64[idx + 1]}
    //                     alt={`Closeup ${idx + 2}`}
    //                     className={`closeup-image closeup-image-${idx + 2} ${
    //                       isMainDisplay ? "view" : ""
    //                     }`}
    //                     loading="lazy"
    //                   />
    //                 )}
    //               </div>
    //             </div>
    //           );
    //         } else if (
    //           idx === 1 &&
    //           product?.closeup_images_base64?.length === 2
    //         ) {
    //           // Special case for only two closeup images: second image should be full
    //           return (
    //             <div
    //               key={idx}
    //               className={`closeup-image-container closeup-image-${
    //                 idx + 1
    //               }-container ${isMainDisplay ? "view" : ""} full`}
    //             >
    //               <img
    //                 src={img}
    //                 alt={`Closeup ${idx + 1}`}
    //                 className={`closeup-image closeup-image-${idx + 1} ${
    //                   isMainDisplay ? "view" : ""
    //                 } full`}
    //                 loading="lazy"
    //               />
    //             </div>
    //           );
    //         } else if (idx !== 1 && idx !== 2) {
    //           // Return the images that are not in the separate div container
    //           const isFull =
    //             product?.closeup_images_base64?.length === 1 ? "full" : "";
    //           return (
    //             <div
    //               key={idx}
    //               className={`closeup-image-container closeup-image-${
    //                 idx + 1
    //               }-container ${isMainDisplay ? "view" : ""} ${isFull}`}
    //             >
    //               <img
    //                 src={img}
    //                 alt={`Closeup ${idx + 1}`}
    //                 className={`closeup-image closeup-image-${idx + 1} ${
    //                   isMainDisplay ? "view" : ""
    //                 } ${isFull}`}
    //                 loading="lazy"
    //               />
    //             </div>
    //           );
    //         }
    //         return null; // Return null for skipped indexes to prevent rendering
    //       })}
    //     {product?.back_image_base64 && (
    //       <div
    //         className={`product-back-image-container ${
    //           isMainDisplay ? "view" : "preview"
    //         } ${product?.closeup_images_base64?.length === 1 ? "full" : ""} ${
    //           !product.closeup_images_base64 ? "back-full" : ""
    //         }`}
    //       >
    //         <img
    //           src={product?.back_image_base64}
    //           className={`product-back-image ${
    //             isMainDisplay ? "view" : "preview"
    //           } ${
    //             product?.closeup_images_base64 &&
    //             product?.closeup_images_base64?.length === 1
    //               ? "full"
    //               : ""
    //           } ${
    //             product?.closeup_images_base64?.length === 1 ? "full-view" : ""
    //           } ${
    //             !product.closeup_images_base64
    //               ? "back-full"
    //               : "preview-back-full"
    //           }`}
    //           alt="Back"
    //           loading="lazy"
    //         />
    //       </div>
    //     )}
    //   </div>
    // </div>,
  ];

  return (
    <div
      className={`product-preview ${isMainDisplay ? "view" : ""}`}
      style={{ cursor: "pointer" }}
    >
      {isMainDisplay ? (
        <>{slides[activeSlide]}</>
      ) : (
        <>
          {slides.map((slide, index) => (
            <div
              key={index}
              className={` ${index === activeSlide ? "active-slide" : ""} ${
                isMainDisplay ? "view" : ""
              }`}
              onClick={() => onSlideClick(index)}
            >
              {slide}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ProductPreview;
