import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserDetailsFromLocalStorage } from "../LocalStorage";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { getBestSeller } from "../../redux/action/addProduct";
import video from "../../images/landingpage-video.mp4";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./LandingPage.css";

const LandingPage = ({
  getBestSeller,
  bestseller,
  loading,
  setSelectedGender,
  setActiveItem,
}) => {
  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 5,
    slidesToScroll: 5,
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const activeButton = localStorage.setItem("activeItem", "Dashboard");
    setActiveItem(activeButton);
    sessionStorage.setItem("previousUrl", window.location.href);
  }, [location, setActiveItem]);

  useEffect(() => {
    const userDetails = getUserDetailsFromLocalStorage();
    if (userDetails?.user) {
      getBestSeller(userDetails?.user?._id, userDetails?.user?.role);
    }
  }, [getBestSeller]);

  useEffect(() => {
    // Function to update the slider settings based on screen width
    const updateSliderSettings = () => {
      const screenWidth = window.innerWidth;
      let slidesToShow = 5;
      let slidesToScroll = 5;

      if (screenWidth < 768) {
        slidesToShow = 1;
        slidesToScroll = 1;
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        slidesToShow = 2;
        slidesToScroll = 2;
      } else if (screenWidth >= 1024 && screenWidth < 1501) {
        slidesToShow = 3;
        slidesToScroll = 3;
      } else {
        slidesToShow = 5;
        slidesToScroll = 5;
      }

      
      //  if (screenWidth < 580) {
      //    slidesToShow = 1;
      //    slidesToScroll = 1;
      //  } else if (screenWidth >= 580 && screenWidth < 768) {
      //    slidesToShow = 2;
      //    slidesToScroll = 2;
      //  } else if (screenWidth >= 768 && screenWidth < 1024) {
      //    slidesToShow = 3;
      //    slidesToScroll = 3;
      //  } else if (screenWidth >= 1024 && screenWidth < 1501) {
      //    slidesToShow = 4;
      //    slidesToScroll = 4;
      //  } else {
      //    slidesToShow = 5;
      //    slidesToScroll = 5;
      //  }

      if (bestseller?.data?.length < 3) {
        slidesToShow = 1;
        slidesToScroll = 1;
      } else if (bestseller?.data?.length <= 6) {
        slidesToShow = 3;
        slidesToScroll = 3;
      } else if (bestseller?.data?.length <= 10) {
        slidesToShow = 4;
        slidesToScroll = 4;
      }

      setSliderSettings({
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow,
        slidesToScroll,
      });
    };

    // Initial setting on component mount
    updateSliderSettings();

    // Update settings on window resize
    window.addEventListener("resize", updateSliderSettings);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", updateSliderSettings);
    };
  }, [bestseller?.data?.length]);

  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />
              <stop offset="50%" stopColor="#196D92" />
              <stop offset="100%" stopColor="#49883E" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );

  if (loading || !bestseller || !bestseller.data) {
    return <GradientCircularProgress />;
  }

  const handleViewProductsClick = () => {
    const activeButton = localStorage.setItem("activeItem", "Home");
    setActiveItem(activeButton);
    setSelectedGender([]);
    navigate("/dashboard");
  };

  const navigateToProductDetails = (productId) => {
    navigate(`/productdetails/${productId}`);
  };
  console.log("bestseller", bestseller?.data);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="landingPage-video-background">
        <video autoPlay muted loop id="video-bg">
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <div className="landingpage-main">
        <div className="landingpage-title">
          <span className="landingpage-large-span">
            Your Style’s Headquarters
          </span>
          <span className="landingpage-small-span">
            Revolutionize The Way You Manage Your Products - Simplifying The
            things Like Never Before!
          </span>
          <button
            className="view-products-button"
            onClick={handleViewProductsClick}
          >
            View All Products
          </button>
        </div>
        {bestseller?.data?.length > 0 && (
          <div className="bestseller">
            <div className="text-container">
              <span className="best-seller-text">Our Bestsellers For You</span>
            </div>
            <Slider {...sliderSettings}>
              {bestseller?.data?.map(
                ({ _id, front_image, description, fabric_info }) => (
                  <div
                    key={_id}
                    className="bestseller-card"
                    onClick={() => navigateToProductDetails(_id)}
                  >
                    <div className="bestseller-image-container">
                      <img src={front_image} alt={description} />
                    </div>
                    <div className="bestseller-footer">
                      <span className="bestseller-big-text">{description}</span>
                      <span className="bestseller-small-text">
                        {fabric_info[0]?.content}
                      </span>
                    </div>
                  </div>
                )
              )}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bestseller: state.bestSeller.product,
  loading: state.bestSeller.loading,
});

const mapDispatchToProps = {
  getBestSeller,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
