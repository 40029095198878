import React from "react";
import Dialog from "@mui/material/Dialog";
import close from "../../images/icons/close.svg";

const PreviewImage = ({ openImagePopup, selectedImage, handleCancel }) => {
  return (
    <Dialog
      open={openImagePopup}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "hidden",
          position: "relative", // Enable absolute positioning inside the dialog
        },
      }}
      sx={{
        "& .MuiPaper-root.MuiDialog-paper": {
          "@media (max-width: 600px)": {
            width: "100% !important", // Adjust width for small screens
            padding: "16px", // Add some padding for better appearance
            borderRadius: "12px", // Optional: Adjust border radius
            height: "100% !important",
            maxHeight: "100% !important",
            padding: "16px",
            borderRadius: "0px",
            margin: "0px",
          },
        },
      }}
    >
      {/* Close button */}
      <div
        onClick={handleCancel}
        style={{
          position: "absolute",
          top: "16px",
          right: "16px",
          cursor: "pointer",
          zIndex: 1000,
        }}
      >
        <img src={close} alt="Close" width={16} height={16} />
      </div>

      {/* Image container */}
      <div
        style={{
          padding: "24px",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {selectedImage && (
          <img
            src={selectedImage}
            alt="Selected Preview"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        )}
      </div>
    </Dialog>
  );
};

export default PreviewImage;
