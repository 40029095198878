import React, { useState, useEffect } from "react";
import viewDocument from "../../images/icons/viewDocument.svg";
import brandEdit from "../../images/icons/brandEdit.svg";
import brandDelete from "../../images/icons/brandDelete.svg";
import deletePopup from "../../images/icons/deletePopup.svg";
import docFolder from "../../images/icons/docFolder.svg";
import close from "../../images/icons/docClose.svg";
import pdf from "../../images/icons/pdf.svg";
import filterIcon from "../../images/icons/filter.svg";
import filterGif from "../../images/icons/filter.gif";
import dayjs from "dayjs";
import space from "../../images/space.gif";

import arrow from "../../images/icons/arrow-down.svg";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import DropdownWithCheckbox from "../dashboard/DropdownWithCheckbox";
import {
  getTrendBrandDetails,
  getProductsDropDownValues,
  deleteProduct,
  getBrandDetails,
} from "../../redux/action/addProduct";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TableVirtuoso } from "react-virtuoso";
import vector from "../../images/icons/Vector.svg";
import vectorUp from "../../images/icons/Vector-up.svg";
import "./TrendInfo.css";
import styled from "styled-components";
import { InputAdornment } from "@mui/material";
import { getUserDetailsFromLocalStorage } from "../LocalStorage";
import toast from "react-hot-toast";

const StyledDatePicker = styled(DesktopDatePicker)`
  width: 180px; /* Adjust width as needed */
  height: 41px; /* Adjust height as needed */
`;

const CustomIcon = () => {
  return (
    <InputAdornment position="end">
      {/* Replace with your custom icon */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4 14h-4v-2h4v2z" />
      </svg>
    </InputAdornment>
  );
};
const TrendInfo = ({
  getTrendBrandDetails,
  deleteProduct,
  getBrandDetails,
  getProductsDropDownValues,
  dropdownValues,
  loading,
}) => {
  const [currentTrend, setCurrentTrend] = useState();
  const [open, setOpen] = React.useState(false);
  const [openDOC, setOpenDOC] = React.useState(false);
  const [brandId, setBrandId] = useState(null);
  const navigate = useNavigate();
  const { trend, brand } = useParams();
  const [currentFolder, setCurrentFolder] = useState([]);
  const [folderStructure, setFolderStructure] = useState([]);
  const [path, setPath] = useState([]);
  const datePickerFocused = false;
  const [anchorEl, setAnchorEl] = useState(null);
  const openC = Boolean(anchorEl);
  const gender = localStorage.getItem("gender") || "";
  const [filterIsHovered, setFilterIsHovered] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState([]);

  const [selectedDate, setSelectedDate] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [role, setRole] = useState("");
  const [brandDetails, setBrandDetails] = useState([]);
  const [showLoading, setShowLoading] = useState(true); // New state to control loading spinner

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };
  console.log("dropdownValues", dropdownValues);

  const handleDelete = async () => {
    setShowLoading(true);
    try {
      await deleteProduct(currentTrend, brandId);
      setOpen(false);

      const userDetails = getUserDetailsFromLocalStorage();

      if (userDetails?.user) {
        const encodedBrand = encodeURIComponent(brand);
        const brandDetails = await getTrendBrandDetails(
          userDetails.user._id,
          userDetails.user.role,
          currentTrend,
          encodedBrand
        );

        setBrandDetails(brandDetails);
        toast.success(`${currentTrend} Trend Deleted successfully!`);
        localStorage.setItem("check", true);
      }
      setShowLoading(false);
    } catch (error) {
      console.error("Failed to delete product or fetch brand details:", error);
    }
  };

  useEffect(() => {
    const userDetails = getUserDetailsFromLocalStorage();
    setRole(userDetails.user.role);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const userDetails = getUserDetailsFromLocalStorage();

      if (userDetails?.user) {
        try {
          const brandDetails = await getTrendBrandDetails(
            userDetails.user._id,
            userDetails.user.role,
            trend,
            encodeURIComponent(brand)
          );
          setBrandDetails(brandDetails);
        } catch (error) {
          console.error("Failed to fetch brand details:", error);
        }
      }

      setCurrentTrend(trend);

      try {
        await getProductsDropDownValues();
      } catch (error) {
        console.error("Failed to fetch product dropdown values:", error);
      }
    };

    fetchData();
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  }, [getTrendBrandDetails, getProductsDropDownValues, trend, brand]);
  useEffect(() => {
    const check = localStorage.getItem("check");
    console.log("checkBefore", check, loading, brandDetails?.data?.length);

    if (!loading) {
      if (
        check &&
        check !== "false" &&
        check !== "0" &&
        brandDetails &&
        brandDetails?.data &&
        brandDetails?.data?.length === 0
      ) {
        console.log("checkAfter", check, loading, brandDetails?.data?.length);
        navigate("/viewtrends");
      }
    } else {
      console.log("Loading is true, skipping navigation");
    }
  }, [brandDetails, navigate, loading]);

  const handleTrendChange = (trend) => {
    setCurrentTrend(trend);
    localStorage.setItem("trend", trend);
    navigate(`/viewtrends`);
  };
  const handleDeletePopup = async (type, brandId) => {
    setCurrentTrend(trend);
    setBrandId(brandId);
    setOpen(true);
  };
  const handleEditBrandClick = (trend, brandId) => {
    navigate(`/editbrand/${trend}/${brandId}`);
    localStorage.setItem("check", true);
  };

  const handleOpenDOCS = async (trend, id) => {
    setOpenDOC(true);
    const data = await getBrandDetails(trend, id);
    if (data) {
      const folder = buildFolderStructure(data.documents, data._id);
      setCurrentFolder(folder);
      setPath([]);
      setFolderStructure(folder);
      console.log("current", currentFolder);
    }
  };

  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />{" "}
              {/* Change color to #26408A */}
              <stop offset="50%" stopColor="#196D92" />{" "}
              {/* Change color to #196D92 */}
              <stop offset="100%" stopColor="#49883E" />{" "}
              {/* Change color to #49883E */}
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );

  useEffect(() => {
    // Function to set the --vh variable
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    // Initial call to set the --vh value
    setVh();

    // Update the --vh value on window resize
    window.addEventListener("resize", setVh);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);
  if (showLoading || loading) {
    return <GradientCircularProgress />;
  }

  function createData(
    id,
    Date,
    Categories,
    Department,
    Season,
    Documents,
    Edit
  ) {
    return { id, Date, Categories, Department, Documents, Season, Edit };
  }
  let columns;
  if (role === "Admin" || role === "SuperAdmin" || role === "Operator") {
    columns = [
      {
        width: 100,
        label: "Date",
        dataKey: "Date",
      },
      {
        width: 100,
        label: "Categories",
        dataKey: "Categories",
      },
      {
        width: 100,
        label: "Department",
        dataKey: "Department",
      },
      {
        width: 100,
        label: "Documents",
        dataKey: "Documents",
      },
      {
        width: 100,
        label: "Season",
        dataKey: "Season",
      },
      {
        width: 100,
        label: "",
        dataKey: "Edit",
        numeric: true,
      },
    ];
  } else {
    columns = [
      {
        width: 100,
        label: "Date",
        dataKey: "Date",
      },
      {
        width: 100,
        label: "Categories",
        dataKey: "Categories",
      },
      {
        width: 100,
        label: "Department",
        dataKey: "Department",
      },
      {
        width: 100,
        label: "Season",
        dataKey: "Season",
      },
      {
        width: 100,
        label: "Documents",
        dataKey: "Documents",
      },
    ];
  }
  console.log("brandDetails", brandDetails);
  const transformedData = brandDetails?.data?.map((item) => {
    const updatedDate = new Date(item.updatedAt)
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, " ");

    return [
      updatedDate,
      item.categories,
      item.department,
      item.season,
      item._id,
    ];
  });
  const rows = transformedData?.map((data, index) =>
    createData(index, ...data)
  );

  const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
      <Table
        {...props}
        sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
      />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
  };

  function fixedHeaderContent() {
    return (
      <TableRow
        sx={{
          height: "40px",
        }}
      >
        {columns?.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.numeric || false ? "right" : "left"}
            style={{ width: column.width }}
            sx={{
              backgroundColor: "background.paper",
              height: "60px",
              fontSize: "15px", // Customize the font size
              fontFamily: "CalibriBold",
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function rowContent(_index, row) {
    return (
      <React.Fragment>
        {columns?.map((column) => (
          <TableCell
            sx={{
              fontSize: "16px", // Customize the font size
              fontFamily: "Calibri",
            }}
            key={column.dataKey}
            align={column.numeric || false ? "right" : "left"}
          >
            {column.dataKey === "Documents" ? (
              <button
                className="document-button"
                onClick={() => handleOpenDOCS(trend, row["Documents"])}
              >
                <img
                  src={viewDocument}
                  alt={column.dataKey}
                  style={{ width: "16px", height: "16px" }}
                />
                <span className="button-text">Documents</span>
              </button>
            ) : column.dataKey === "Edit" ? (
              <div className="brand-buttons">
                <button
                  className="edit-button"
                  onClick={() => handleEditBrandClick(trend, row["Documents"])}
                >
                  <img
                    src={brandEdit}
                    alt={column.dataKey}
                    style={{ width: "24px", height: "24px" }}
                  />
                </button>
                <button
                  className="edit-button"
                  onClick={() => handleDeletePopup(trend, row["Documents"])}
                >
                  <img
                    src={brandDelete}
                    alt={column.dataKey}
                    style={{ width: "24px", height: "24px" }}
                  />
                </button>
              </div>
            ) : (
              row[column.dataKey]
            )}
          </TableCell>
        ))}
      </React.Fragment>
    );
  }

  const handleClose = () => {
    setOpen(false);
    setOpenDOC(false);
    setAnchorEl(null);
  };

  const buildFolderStructure = (documents, startFolderName) => {
    let shouldStartBuilding = false;
    const structure = { folders: [], files: [] };
    for (const url of documents) {
      const parts = url.split("/");
      if (parts.includes(startFolderName)) {
        shouldStartBuilding = true;
      }
      if (!shouldStartBuilding) continue;

      let currentFolder = structure;
      let folderIndex = parts.findIndex((part) => part === startFolderName);
      for (let i = folderIndex + 1; i < parts?.length; i++) {
        const folderName = parts[i];
        if (i === parts?.length - 1) {
          currentFolder?.files?.push({ name: folderName, url });
        } else {
          let folder = currentFolder?.folders?.find(
            (f) => f.name === folderName
          );
          if (!folder) {
            folder = { name: folderName, folders: [], files: [] };
            currentFolder?.folders?.push(folder);
          }
          currentFolder = folder;
        }
      }
    }
    return structure;
  };

  const handleFileClick = async (fileUrl) => {
    try {
      // Fetch the file at the URL
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }

      // Get the file name and extension
      const fileName = fileUrl.split("/").pop();
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Convert the file to base64
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;

        if (fileExtension === "pdf") {
          // Open PDF files using a PDF viewer
          const pdfViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
            fileUrl
          )}`;
          window.open(pdfViewerUrl, "_blank");
        } else if (
          fileExtension === "ppt" ||
          fileExtension === "pptx" ||
          fileExtension === "doc" ||
          fileExtension === "docx"
        ) {
          // Open PPT, DOC, and DOCX files using Office viewer
          const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            fileUrl
          )}`;
          window.open(officeViewerUrl, "_blank");
        } else if (fileExtension === "svg") {
          // Handle SVG files separately
          const reader = new FileReader();
          reader.onloadend = () => {
            const svgContent = reader.result;

            const htmlContent = `
<html>
  <head>
    <style>
      body, html {
        height: 100vh;
        margin: 0;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg {
        max-height: 100vh;
        max-width: 100vw;
        object-fit: contain;
      }
    </style>
  </head>
  <body>
    ${svgContent}
  </body>
</html>`;

            const blobHtml = new Blob([htmlContent], { type: "text/html" });
            const htmlUrl = URL.createObjectURL(blobHtml);
            // Open the temporary HTML file in a new tab
            window.open(htmlUrl, "_blank");

            // Clean up by revoking the object URL after a delay
            setTimeout(() => {
              URL.revokeObjectURL(htmlUrl);
            }, 10000); // 10 seconds delay before revoking the URL
          };
          reader.readAsText(blob);
        } else if (
          fileExtension === "png" ||
          fileExtension === "jpg" ||
          fileExtension === "jpeg" ||
          fileExtension === "gif"
        ) {
          // Handle image files
          const htmlContent = `
<html>
  <head>
    <style>
      body, html {
        height: 100vh;
        margin: 0;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        max-height: 100vh;
        max-width: 100vw;
        object-fit: contain;
      }
    </style>
  </head>
  <body>
    <img src="${base64data}">
  </body>
</html>`;

          const blobHtml = new Blob([htmlContent], { type: "text/html" });
          const htmlUrl = URL.createObjectURL(blobHtml);
          // Open the temporary HTML file in a new tab
          window.open(htmlUrl, "_blank");

          // Clean up by revoking the object URL after a delay
          setTimeout(() => {
            URL.revokeObjectURL(htmlUrl);
          }, 10000); // 10 seconds delay before revoking the URL
        } else if (fileExtension === "txt" || fileExtension === "csv") {
          // Handle text files
          const reader = new FileReader();
          reader.onloadend = () => {
            const textContent = reader.result;

            const htmlContent = `
<html>
  <head>
    <style>
      body, html {
        height: 100vh;
        margin: 0;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      pre {
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    </style>
  </head>
  <body>
    <pre>${textContent}</pre>
  </body>
</html>`;

            const blobHtml = new Blob([htmlContent], { type: "text/html" });
            const htmlUrl = URL.createObjectURL(blobHtml);
            // Open the temporary HTML file in a new tab
            window.open(htmlUrl, "_blank");

            // Clean up by revoking the object URL after a delay
            setTimeout(() => {
              URL.revokeObjectURL(htmlUrl);
            }, 10000); // 10 seconds delay before revoking the URL
          };
          reader.readAsText(blob);
        } else if (fileExtension === "mp3" || fileExtension === "wav") {
          // Handle audio files
          const htmlContent = `
<html>
  <head>
    <style>
      body, html {
        height: 100vh;
        margin: 0;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      audio {
        width: 100%;
      }
    </style>
  </head>
  <body>
    <audio controls>
      <source src="${base64data}" type="audio/${fileExtension}">
      Your browser does not support the audio element.
    </audio>
  </body>
</html>`;

          const blobHtml = new Blob([htmlContent], { type: "text/html" });
          const htmlUrl = URL.createObjectURL(blobHtml);
          // Open the temporary HTML file in a new tab
          window.open(htmlUrl, "_blank");

          // Clean up by revoking the object URL after a delay
          setTimeout(() => {
            URL.revokeObjectURL(htmlUrl);
          }, 10000); // 10 seconds delay before revoking the URL
        } else if (fileExtension === "mp4" || fileExtension === "webm") {
          // Handle video files
          const htmlContent = `
<html>
  <head>
    <style>
      body, html {
        height: 100vh;
        margin: 0;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      video {
        width: 100%;
        height: 100%;
      }
    </style>
  </head>
  <body>
    <video controls>
      <source src="${base64data}" type="video/${fileExtension}">
      Your browser does not support the video element.
    </video>
  </body>
</html>`;

          const blobHtml = new Blob([htmlContent], { type: "text/html" });
          const htmlUrl = URL.createObjectURL(blobHtml);
          // Open the temporary HTML file in a new tab
          window.open(htmlUrl, "_blank");

          // Clean up by revoking the object URL after a delay
          setTimeout(() => {
            URL.revokeObjectURL(htmlUrl);
          }, 10000); // 10 seconds delay before revoking the URL
        } else {
          // For unsupported file types, show an alert
          alert("Unsupported file type: " + fileExtension);
        }
      };
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFolderClick = (folder) => {
    setPath([...path, currentFolder]);
    setCurrentFolder(folder);
  };

  const handleBreadcrumbClick = (index) => {
    const newPath = path.slice(0, index);
    setCurrentFolder(path[index]);
    setPath(newPath);
  };

  const handleBackClick = () => {
    setPath([]);
    setCurrentFolder(folderStructure); // assuming initialFolder is the starting folder
  };
  const handleTrend = () => {
    navigate(-1);
  };
  const renderBreadcrumbs = () => {
    if (path.length === 0) return null;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "24px 24px 0px 24px",
        }}
      >
        <img
          src={docFolder}
          alt=""
          width={28}
          height={28}
          onClick={handleBackClick}
          style={{ cursor: "pointer" }}
        />
        {path?.map((folder, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <div style={{ margin: "0 3px" }}>
                <img src={arrow} alt="" />
              </div>
            )}
            <span
              className="selected-brand"
              onClick={() => handleBreadcrumbClick(index)}
              style={{ cursor: "pointer", margin: "0 3px" }}
            >
              {folder.name}
            </span>
          </React.Fragment>
        ))}
        <div style={{ margin: "0 3px" }}>
          <img src={arrow} alt="" />
        </div>
        <span style={{ margin: "0 3px" }} className="selected-brand">
          {currentFolder.name}
        </span>
      </div>
    );
  };
  const renderFolder = (folder) => {
    if (!folder || !folder.folders || !folder.files) {
      return null; // Return null or handle the case where folder or its properties are undefined
    }

    return (
      <div>
        {renderBreadcrumbs()}
        <div className="folder-files">
          {folder?.folders?.map((subfolder, index) => (
            <div
              key={index}
              onClick={() => handleFolderClick(subfolder)}
              className="folder-set"
            >
              <img src={docFolder} alt="" width={40} height={40} />
              <span className="doc-text">{subfolder.name}</span>
            </div>
          ))}
          {folder?.files?.map((file, index) => (
            <div
              key={index}
              className="folder-set"
              onClick={() => handleFileClick(file.url)}
            >
              <img src={pdf} alt="" width={40} height={40} />
              <span className="doc-text">{file.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };
  // const handleOpen = () => {
  //   setDatePickerFocused(true);
  // };

  // const handleDateClose = () => {
  //   setDatePickerFocused(false);
  // };
  console.log("datePickerFocused", datePickerFocused);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  console.log("selectedDepartment", selectedDepartment);
  const products = rows?.filter((product) => {
    const departmentFilter =
      selectedDepartment?.length === 0 ||
      selectedDepartment?.includes(product.Department);

    const categoryFilter =
      selectedCategories?.length === 0 ||
      selectedCategories?.includes(product.Categories);

    const seasonFilter =
      selectedSeason?.length === 0 || selectedSeason?.includes(product.Season);

    const dateFilter = selectedDate
      ? dayjs(product.Date, "DD MMM YYYY").isSame(dayjs(selectedDate), "day")
      : true;

    return departmentFilter && categoryFilter && dateFilter && seasonFilter;
  });

  const handleClear = () => {
    setSelectedCategories([]);
    setSelectedDate(null);
    setSelectedDepartment([]);
  };
  console.log("products", products);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "502px",
            height: "333px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "32px 80px",
          },
        }}
        sx={{ "& .MuiDialogContent-root": { overflow: "hidden" } }}
      >
        <img src={deletePopup} alt="" width={100} height={100} />
        <DialogTitle id="alert-dialog-title">
          {"Are You Sure You Want to Delete ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This Trend Will Be Deleted Immediately.
            <br /> You Can’t Undo This Action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 600,
              color: "#26408A",
              padding: "0.5rem 1rem",
              cursor: "pointer",
              width: "140px",
              height: "40px",
              borderRadius: "20px",
              border: "2px solid transparent",
              backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #26408A, #196D92, #49883E)`,
              backgroundOrigin: "padding-box, border-box",
              backgroundClip: "padding-box, border-box",
              marginRight: "24px",
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            autoFocus
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 600,
              padding: "0.5rem 1rem",
              cursor: "pointer",
              width: "140px",
              height: "40px",
              borderRadius: "20px",
              border: "2px solid transparent",
              backgroundColor: "#BA1A1A",
              color: "#fff",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#BA1A1A",
                border: "2px solid #BA1A1A",
                textTransform: "capitalize",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDOC}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: { xs: "100%", md: "759px" },
            height: { xs: "100%", md: "409px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "hidden",
            "@media (max-width: 850px)": {
              width: "100%",
              height: "100%",
              maxHeight: "100%", // Apply only for <850px
              maxWidth: "100%", // Apply only for <850px
              margin: "0px", // Apply only for <850px
              borderRadius: "0px",
            },
          },
        }}
        sx={{ "& .MuiDialogContent-root": { overflow: "hidden" } }}
      >
        <div className="doc-nav">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <img src={docFolder} alt="" width={28} height={28} />
            <span style={{ fontSize: "18px", fontFamily: "Calibri" }}>
              Documents
            </span>
          </div>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <img src={close} alt="" width={16} height={16} />
          </div>
        </div>
        <div>{openDOC && renderFolder(currentFolder)}</div>
      </Dialog>

      <div className="view-trends">
        <div className="trends-buttons">
          <div className="trends-button-align">
            <div>
              <button
                className={`slected-trends-button ${
                  currentTrend === "Pearl" ? "active" : ""
                }`}
                onClick={() => handleTrendChange("Pearl")}
              >
                <span className="button-text">Pearl Trends</span>
              </button>
            </div>
            <div>
              <button
                className={`slected-trends-button ${
                  currentTrend === "Buyer" ? "active" : ""
                }`}
                onClick={() => handleTrendChange("Buyer")}
              >
                <span className="button-text">Buyer Trends</span>
              </button>
            </div>
          </div>
        </div>

        <div className="view-trends-container">
          <div className="trendsInfo-header">
            <div className="trendInfo-header-text">
              <div style={{ display: "flex" }}>
                <span onClick={handleTrend} className="brand-back">
                  Brands
                  <img src={arrow} alt="" className="back-image" />
                </span>
                <span className="selected-brand">{brand}</span>
              </div>
              <div className="count-circle">
                <span className="brand-count">
                  {brandDetails?.data?.length}
                </span>
              </div>
            </div>
            <div className="trendsInfo-header-buttons">
              <div>
                <button
                  className="filter"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div
                    onMouseEnter={() => setFilterIsHovered(true)}
                    onMouseLeave={() => setFilterIsHovered(false)}
                  >
                    {!filterIsHovered && (
                      <img
                        src={filterIcon}
                        className="trendsInfo-filter-icon"
                        id="editIcon"
                        alt=""
                      />
                    )}
                    {filterIsHovered && (
                      <img
                        src={filterGif}
                        className="trendsInfo-filter-icon"
                        id="editIcon"
                        alt=""
                      />
                    )}
                  </div>
                  <div>
                    <span className="filter-text">Filters</span>
                  </div>
                </button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openC}
                  onClose={() => handleClose(gender)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    "&.MuiMenu-paper": {
                      width: 240,
                      height: 385,
                      overflow: "hidden",
                    },
                    "&.MuiMenuItem-root": {
                      fontSize: "16px",
                      color: "#1A1B21",
                      fontWeight: "400",
                      borderBottom: "1px solid #B5C4FF",
                      overflowY: "auto",

                      "&:hover": {
                        background:
                          "linear-gradient(to right, #26408a 0%, #196d92 20%, #49883e 40%, #26408A 60%)",
                        "-webkit-background-clip": "text",
                        "-webkit-text-fill-color": "transparent",
                        fontSize: "16px",
                        fontWeight: "700",
                      },
                    },
                  }}
                >
                  {(selectedCategories?.length > 0 ||
                    selectedDate ||
                    selectedDepartment?.length > 0) && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "16px",
                      }}
                    >
                      <button className="clear-filter" onClick={handleClear}>
                        Clear Filters
                      </button>
                    </div>
                  )}
                  <div className="scroll">
                    <div className="scroll-inner">
                      <div className="dropdown">
                        <button
                          className="filter-dropdown-button "
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <div className="side-filter-button">
                            <div>
                              <span className="filter-text-button">Date</span>
                            </div>
                            <div>
                              <img src={isOpen ? vectorUp : vector} alt="" />
                            </div>
                          </div>
                        </button>
                        {isOpen && (
                          <div className="date-component">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={["DesktopDatePicker"]}>
                                <StyledDatePicker
                                  format="DD MMM YYYY"
                                  onChange={handleDateChange}
                                  value={selectedDate}
                                  sx={{
                                    "& .MuiOutlinedInput-input": {
                                      height: "0.4375em",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "rgba(224, 230, 238, 1)",
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: "rgba(0, 0, 0, 0.8)",
                                    },
                                    "& .MuiTextField-root": {
                                      minWidth: "180px",
                                    },
                                    "& .MuiInputBase-root.MuiOutlinedInput-root":
                                      {
                                        paddingRight: "0px !important",
                                        borderRadius: "0px !important",
                                      },
                                    "& .MuiButtonBase-root.MuiIconButton-root":
                                      {
                                        backgroundColor: "rgb(205, 229, 255)",
                                        padding: "9px !important",
                                        borderRadius: "0px !important",
                                      },
                                    minWidth: "180px !important",
                                    "@media (max-width: 850px)": {
                                      "& .MuiTextField-root": {
                                        width: "139px",
                                        height: "33px",
                                        minWidth: "156px",
                                      },
                                      "& .MuiOutlinedInput-input": {
                                        padding: "13.5px 5px",
                                        fontSize: "13px",
                                        width: "105px",
                                      },
                                      "& .MuiInputBase-root.MuiOutlinedInput-root":
                                        {
                                          width: "156px",
                                        },
                                      "& .MuiButtonBase-root.MuiIconButton-root":
                                        {
                                          padding: "4px !important",
                                        },
                                      minWidth: "156px !important",
                                      width: "156px !important",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    },
                                  }}
                                  InputProps={{
                                    endAdornment: <CustomIcon />,
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        )}
                      </div>
                      <DropdownWithCheckbox
                        options={dropdownValues?.data[0]?.categories}
                        name="Categories"
                        selectedOptions={selectedCategories}
                        setSelectedOptions={setSelectedCategories}
                      />
                      <DropdownWithCheckbox
                        options={dropdownValues?.data[0]?.department}
                        name="Department"
                        selectedOptions={selectedDepartment}
                        setSelectedOptions={setSelectedDepartment}
                      />
                      <DropdownWithCheckbox
                        options={dropdownValues?.data[0]?.season}
                        name="Season"
                        selectedOptions={selectedSeason}
                        setSelectedOptions={setSelectedSeason}
                      />
                    </div>
                  </div>
                </Menu>
              </div>

              {(role === "Admin" ||
                role === "SuperAdmin" ||
                role === "Operator") && (
                <div>
                  <Link to={`/addtrend/${brand}`} className="add-trend-button">
                    <span className="header-button-text">
                      + Add {currentTrend} Trend
                    </span>
                  </Link>
                </div>
              )}
            </div>
          </div>
          {products?.length > 0 ? (
            <div>
              <Paper className="table-container">
                <TableVirtuoso
                  data={products}
                  components={VirtuosoTableComponents}
                  fixedHeaderContent={fixedHeaderContent}
                  itemContent={rowContent}
                />
              </Paper>
              <div className="mobile-info">
                {products.map((details, index) => (
                  <div key={index} className="details-card">
                    <div className="details-header">
                      <span className="date-text">{details.Date}</span>
                      <button
                        className="document"
                        onClick={() => handleOpenDOCS(trend, details.Documents)}
                      >
                        View Documents
                      </button>
                    </div>
                    <div className="divide-line"></div>
                    <div className="section-align">
                      <div className="section">
                        <span className="text-head">Categories</span>
                        <span className="text-value">{details.Categories}</span>
                      </div>
                      <div className="section">
                        <span className="text-head">Department</span>
                        <span className="text-value">{details.Department}</span>
                      </div>
                      <div className="section">
                        <span className="text-head">Department</span>
                        <span className="text-value">{details.Season}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="no-brands-container1">
              <div className="brand-inner-content1">
                <div>
                  <img src={space} alt="" className="no-brand-img2" />
                </div>
                <div>
                  <span className="no-product-text-big">
                    Seems Like No Filtered Brands Here!!
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  brandDetails: state.trendBrandDetails.trendBrandDetails,
  loading: state.trendBrandDetails.loading,

  brandData: state.brandDetails.brandDetails,
  dropdownValues: state.dropdownValues.dropDownValues,
});

const mapDispatchToProps = {
  getTrendBrandDetails,
  deleteProduct,
  getBrandDetails,
  getProductsDropDownValues,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrendInfo);
