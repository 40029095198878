import React, { useState, useEffect } from "react";
import "./Selection.css";
import close from "../../images/icons/docClose.svg";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { TextField, Chip, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import {
  sendEmail,
  selectProduct,
  findProduct,
} from "../../redux/action/addProduct";
import { Toaster, toast } from "react-hot-toast";

const Selection = ({ openPopup, closePopup, product }) => {
  const dispatch = useDispatch();
  const [emailInput, setEmailInput] = useState("");
  const [user, setUser] = useState(null);

  const [formData, setFormData] = useState({
    recipients: [],
    subject: `Marketing Update: Highlighting ${product?.product_style}`,
    message: "",
    attachments: [],
  });
  console.log("formData", formData);
  const { loading } = useSelector((state) => state.emailDetails);
  const { selectionProduct, selectionLoading, selectionError } = useSelector(
    (state) => state.selectionDetails
  );

  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />{" "}
              {/* Change color to #26408A */}
              <stop offset="50%" stopColor="#196D92" />{" "}
              {/* Change color to #196D92 */}
              <stop offset="100%" stopColor="#49883E" />{" "}
              {/* Change color to #49883E */}
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    if (storedUserDetails) {
      const parsedDetails = JSON.parse(storedUserDetails);
      if (parsedDetails.success) {
        setUser(parsedDetails.user);
      }
    }
    if (product) {
      const selectedAttachments = [
        product.front_image,
        product.back_image,
        ...(product.closeup_images || []),
      ].filter(Boolean); // Remove any falsy values

      setFormData((prevData) => ({
        ...prevData,
        attachments: selectedAttachments,
      }));
    }
  }, [product]); // Runs whenever `product` changes

  const handleKeyDown = (e) => {
    if (["Enter", "Tab", ","].includes(e.key)) {
      e.preventDefault();
      const email = emailInput.trim();

      if (email && isValidEmail(email)) {
        setFormData((prev) => ({
          ...prev,
          recipients: [...prev.recipients, email],
        }));
        setEmailInput(""); // Clear input field after adding
      }
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    setFormData((prev) => ({
      ...prev,
      recipients: prev.recipients.filter((email) => email !== emailToDelete),
    }));
  };
  const handleChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleCommentChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      message: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.recipients.length) {
      toast.error("At least one email is required.");
      return;
    }

    if (!formData.message.trim()) {
      toast.error("Comment cannot be empty.");
      return;
    }

    try {
      const { _id: userId, firstname = "", lastname = "" } = user || {};
      const payload = {
        product_id: product?._id,
        selection: true,
        comment: formData.message,
        selected_by: userId,
        selected_user: `${firstname} ${lastname}`.trim(),
      };

      // Dispatch Redux actions
      await Promise.all([
        dispatch(selectProduct(payload)),
        dispatch(sendEmail(formData)),
      ]);

      toast.success("Product selection updated successfully.");
      closePopup();
    } catch (error) {
      console.error("Error updating product:", error);
      toast.error("Error updating product. Please try again.");
    }
  };

  if (loading || selectionLoading) {
    return <GradientCircularProgress />;
  }

  return (
    <Dialog
      open={openPopup}
      onClose={closePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          width: "471px",
          height: "446px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "hidden",
        },
      }}
      sx={{ "& .MuiDialogContent-root": { overflow: "hidden" } }}
    >
      <Toaster />

      <div className="adduser-nav">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <span>Select the product</span>
        </div>
        <div onClick={closePopup} style={{ cursor: "pointer" }}>
          <img src={close} alt="" width={16} height={16} />
        </div>
      </div>
      <div
        style={{
          padding: "24px",
          width: "100%",
          height: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "24px",
            flexDirection: "column",
          }}
        >
          <TextField
            id="outlined-multiline-flexible"
            name="comment"
            label="Comment"
            multiline
            size="small"
            fullWidth
            minRows={3} // Ensures it looks like a textarea
            maxRows={3}
            value={formData.message}
            onChange={handleCommentChange}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.8)",
              },
              "& .MuiInputLabel-root": {
                color: "rgba(0, 0, 0, 0.8)",
              },
            }}
          />
          <TextField
            fullWidth
            label="Email addresses"
            variant="outlined"
            value={emailInput}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                    maxHeight: "100px",
                    overflowY: "auto",
                  }}
                >
                  {formData?.recipients?.map((email) => (
                    <Chip
                      key={email}
                      label={email}
                      onDelete={() => handleDeleteEmail(email)}
                      sx={{ borderRadius: "4px" }}
                    />
                  ))}
                </Box>
              ),
              style: {
                alignItems: "flex-start",
                padding: "8px",
                flexWrap: "wrap",
              },
            }}
            inputProps={{
              style: {
                flexGrow: 1,
                minWidth: "150px",
                padding: "8px 0",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                flexWrap: "wrap",
                alignItems: "flex-start",
                minHeight: "48px",
                paddingTop: "8px",
              },
              "& .MuiInputLabel-root": {
                transform: "translate(14px, 16px) scale(1)",
                color: "rgba(0, 0, 0, 0.8)",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -9px) scale(0.75)",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0, 0, 0, 0.8)",
              },
            }}
            multiline
            minRows={2} // Ensures it looks like a textarea
            maxRows={2}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "24px",
          }}
        >
          <Button
            onClick={closePopup}
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 600,
              color: "#26408A",
              padding: "0.5rem 1rem",
              cursor: "pointer",
              width: "126px",
              height: "40px",
              borderRadius: "20px",
              border: "1px solid transparent",
              backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #26408A, #196D92, #49883E)`,
              backgroundOrigin: "padding-box, border-box",
              backgroundClip: "padding-box, border-box",
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            autoFocus
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: 600,
              padding: "0.5rem 1rem",
              cursor: "pointer",
              width: "126px",
              height: "40px",
              borderRadius: "20px",
              border: "2px solid transparent",
              background:
                "linear-gradient(to right, #26408A, #196D92,#49883E) padding-box,linear-gradient(to right, #26408A, #196D92,#49883E) border-box",
              color: "#fff",
              textTransform: "capitalize",
            }}
          >
            Send
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default Selection;
