import React, { useState, useEffect } from "react";
import "./ProductDetails.css";
import arrow from "./icons/arrow.svg";
import deleteIcon from "./icons/deleteIcon.svg";
import edit from "./icons/edit.svg";
import pdf from "./icons/pdf.svg";
import deletePopupIcon from "./icons/delete.svg";
import documentIcon from "./icons/document.svg";
import arrowIcon from "../../images/icons/arrow-down.svg";

import { connect } from "react-redux";
import {
  findProduct,
  deleteProduct,
  findProducts,
} from "../../redux/action/addProduct";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import arrowGif from "../../images/icons/arrow.gif";
import editGif from "../../images/icons/edit.gif";
import selectedIcon from "../../images/icons/selected.svg";
import deleteGif from "../../images/icons/delete.gif";
import BestSeller from "../../images/icons/bestSeller.svg";
import { getUserDetailsFromLocalStorage } from "../LocalStorage";
import PreviewImage from "../viewTrends/PreviewImage";
import TextField from "@mui/material/TextField";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";
import { backend_url } from "../../BaseUrl";
import TakeNotes from "./TakeNotes";
import Selection from "./Selection";
import debounce from "lodash.debounce";
import { useSelector } from "react-redux";
import docFolder from "../../images/icons/docFolder.svg";
import close from "../../images/icons/docClose.svg";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "rsuite/esm/useMediaQuery/useMediaQuery";

const ProductDetails = ({
  findProduct,
  product,
  loading,
  deleteProduct,
  findProducts,
  selectedProductsData,
  base64ProductsLoading,
}) => {
  const [deleteIsHovered, setDeleteIsHovered] = useState(false);
  const [openDOC, setOpenDOC] = useState(false);

  const [editIsHovered, seteditIsHovered] = useState(false);
  const [backIsHovered, setbackIsHovered] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [role, setRole] = useState("");
  const { selectionProduct } = useSelector((state) => state.selectionDetails);
  const [openImagePopup, setOpenImagePopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);
  const [selectionOpen, setSelectionOpen] = useState(false);
  const [localProduct, setLocalProduct] = useState(null);
  const [imageBlobs, setImageBlobs] = useState([]);
  const navigate = useNavigate();
  const { productId } = useParams();
  const [currentFolder, setCurrentFolder] = useState([]);
  const [folderStructure, setFolderStructure] = useState([]);
  const [hoverPos, setHoverPos] = useState(null);
  const [path, setPath] = useState([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  // Add this utility function at the top of your file
  const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function (...args) {
      if (!lastRan) {
        func.apply(this, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(() => {
          if (Date.now() - lastRan >= limit) {
            func.apply(this, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };

  // Modified event handler
  const handleMouseMove = throttle((e) => {
    const imageRect = e.target.getBoundingClientRect();
    const xPercent = ((e.clientX - imageRect.left) / imageRect.width) * 100;
    const yPercent = ((e.clientY - imageRect.top) / imageRect.height) * 100;

    const overlayWidth = 300;
    const overlayHeight = 300;
    let overlayX = e.clientX + 10;
    let overlayY = e.clientY + 10;

    // Adjust horizontal position if needed
    if (overlayX + overlayWidth > window.innerWidth) {
      overlayX = e.clientX - overlayWidth - 10;
    }
    overlayX = Math.max(0, overlayX);

    // Adjust vertical position if needed
    if (overlayY + overlayHeight > window.innerHeight) {
      overlayY = e.clientY - overlayHeight - 10;
    }
    overlayY = Math.max(0, overlayY);

    setHoverPos({
      x: xPercent,
      y: yPercent,
      overlayX,
      overlayY,
    });
  }, 16); // Throttle to ~60fps

  const handleMouseLeave = () => {
    setHoverPos(null);
  };
  console.log("product", product);
  const [selection, setSelection] = useState({
    selection: product?.selection ?? false,
    comment: product?.comment ?? "",
  });
  const [user, setUser] = useState(null);
  console.log("user", user);
  const dashboardUrl = "/dashboard";
  const landingPageUrl = "/landingpage";
  const handleEditClick = () => {
    navigate(`/editproduct/${productId}`);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleToggleSelection = () => {
  //   setSelection((prev) => ({
  //     ...prev,
  //     selection: !prev.selection,
  //   }));
  // };
  const handleSelectionOpen = async () => {
    setSelectionOpen(true);
  };

  const handleSelectionClose = async () => {
    setSelectionOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelection((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotesOpen = async () => {
    if (!product) return;

    setNotesOpen(true);
    console.log("localProduct", localProduct);
  };
  useEffect(() => {
    findProducts([productId]);
  }, [findProducts, productId]);

  const images = [
    selectedProductsData?.[0]?.front_image_base64,
    selectedProductsData?.[0]?.back_image_base64,
    ...(selectedProductsData?.[0]?.closeup_images_base64 || []),
  ].filter(Boolean);
  // Function to clean up images and close notes
  const handleNotesClose = () => {
    setNotesOpen(false);

    // Cleanup blob URLs
    imageBlobs.forEach((url) => URL.revokeObjectURL(url));
    setImageBlobs([]); // Reset stored blobs
    setLocalProduct(null); // Reset product data
  };
  const handleDeleteProduct = async () => {
    await deleteProduct("product", productId);
    navigate("/dashboard");
  };
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  useEffect(() => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    if (storedUserDetails) {
      const parsedDetails = JSON.parse(storedUserDetails);
      if (parsedDetails.success) {
        setUser(parsedDetails.user);
      }
    }
  }, []);
  useEffect(() => {
    // Create the ResizeObserver callback
    const resizeObserver = new ResizeObserver(
      debounce((entries) => {
        entries.forEach((entry) => {
          console.log("Resized element:", entry.target); // Logs the element being resized
          // Your resize logic here
        });
      }, 200)
    );

    // Observe all elements (you can target a specific container if needed)
    const elements = document.querySelectorAll("*");
    elements.forEach((element) => {
      resizeObserver.observe(element);
    });

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (productId) {
      const fetchProduct = async () => {
        try {
          const fetchedProduct = await findProduct(productId); // Fetch product
          setSelection({
            selection: fetchedProduct?.data?.selection ?? false,
            comment: fetchedProduct?.data?.comment ?? "",
          });
          console.log("selection", fetchedProduct?.data?.comment);
        } catch (error) {
          console.error("Error fetching product:", error);
          toast.error("Error fetching product details.");
        }
      };
      fetchProduct();
    }
  }, [findProduct, productId, selectionProduct]);

  useEffect(() => {
    const userDetails = getUserDetailsFromLocalStorage();
    if (userDetails?.user) {
    }
    setRole(userDetails.user.role);
  }, []);

  useEffect(() => {
    if (product && product.front_image) {
      setSelectedImage(product.front_image);
    }
  }, [product]);

  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />{" "}
              <stop offset="50%" stopColor="#196D92" />{" "}
              <stop offset="100%" stopColor="#49883E" />{" "}
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );
  useEffect(() => {
    // Function to set the --vh variable
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    // Initial call to set the --vh value
    setVh();

    // Update the --vh value on window resize
    window.addEventListener("resize", setVh);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", setVh);
    };
  }, []);
  // Usage:
  if (loading || !product) {
    return <GradientCircularProgress />;
  }

  const fabricDetails = [
    {
      label: "Brand",
      value: product.brand,
    },
    {
      label: "Sub Brand",
      value: product.sub_brand,
    },
    {
      label: "Department",
      value: product.department,
    },
    {
      label: "Description",
      value: product.description,
    },
    {
      label: "Season",
      value: product.season,
    },
    {
      label: "Style Name / Reference. No",
      value: product.product_style,
    },
    {
      label: "Gender",
      value: product.gender,
    },
    {
      label: "Categories",
      value: product.categories,
    },
  ];

  const fabricProd = [
    {
      label: "Phase",
      value: product.phase,
    },
    {
      label:
        product.phase === "Production" ? "Shipment Date" : "Development Date",
      value: new Date(product.shipment_date)
        .toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
        .replace(",", ""),
    },
  ];
  const productSelection = {
    selection: product.selection ?? false, // Use fetched value or fallback to false
    comment: product.comment ?? "", // Use fetched value or fallback to empty string
  };
  // Conditional fields based on product phase
  if (product.phase !== "Development") {
    fabricProd.push(
      {
        label: "Order Quantity (Pcs)",
        value: product.order_quantity,
      },
      {
        label: `Garment FOB (${product.garment_currency || "USD"})`,
        value: product.garment_fob,
      },
      {
        label: "Factory",
        value: product.factory,
      }
    );
  }

  // Fields common to both phases
  fabricProd.push(
    {
      label: "Country",
      value: product.country,
    },
    {
      label: "Customer",
      value: product.customer,
    },
    {
      label: "Division",
      value: product.division,
    }
  );

  // Conditionally push 'Comment' only if product.selection is true
  if (product.selection) {
    fabricProd.push(
      {
        label: "Selected By",
        value: product.selected_user,
      },
      {
        label: "Comment",
        value: product.comment,
      }
    );
  }

  // const documents = [
  //   {
  //     label: "documents",
  //     value: product.documents.map((url) => url.split("/").pop()),
  //   },
  // ];

  console.log("selectedImage", selectedImage);
  const handleDashboard = () => {
    const previousUrl = sessionStorage.getItem("previousUrl"); // Retrieve the previous URL

    // Debugging to see the previous URL

    // Check if the previous page is either the dashboard or landing page
    if (previousUrl && previousUrl.includes(dashboardUrl)) {
      setSelectedImage(null);
      navigate(dashboardUrl); // Go back to the previous page
    } else if (previousUrl && previousUrl.includes(landingPageUrl)) {
      setSelectedImage(null);
      navigate(landingPageUrl);
    } else {
      setSelectedImage(null);
      navigate(dashboardUrl); // Navigate to the dashboard
    }
  };

  const handleImageZoomClick = (image) => {
    setSelectedImage(image); // Set the image to preview
    setOpenImagePopup(true); // Open the dialog
  };

  const handleCancel = () => {
    setOpenImagePopup(false); // Close the dialog
  };

  const handleSelect = async (e) => {
    e.preventDefault();

    const { _id: userId, firstname, lastname } = user || {};

    // Check if the comment is empty
    if (!selection.comment.trim()) {
      toast.error("Comment cannot be empty.");
      return; // Stop if the comment is invalid
    }

    try {
      const payload = {
        product_id: product._id,
        selection: true,
        comment: selection.comment,
        selected_by: userId,
        selected_user: `${firstname || ""} ${lastname || ""}`.trim(),
      };

      await axios.put(`${backend_url}/product/selectProduct`, payload, {
        headers: { "Content-Type": "application/json" },
      });

      toast.success("Product selection updated successfully.");

      // Reset selection comment after submission
      setSelection({ selection: true, comment: "" });

      // Fetch the updated product details
      await findProduct(product._id);
    } catch (error) {
      console.error("Error updating product:", error);
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Error updating product. Please try again.";
      toast.error(errorMessage);
    }
  };

  const handleUnselect = async (e) => {
    e.preventDefault();

    try {
      const payload = {
        product_id: product._id,
        selection: false,
        comment: "",
        selected_by: null,
        selected_user: "",
      };

      await axios.put(`${backend_url}/product/selectProduct`, payload, {
        headers: { "Content-Type": "application/json" },
      });

      toast.success("Product Unselected");
      setSelection({
        selection: false,
      });
      // Fetch the updated product
      await findProduct(product._id);
    } catch (error) {
      console.error("Error updating product:", error);
      const errorMessage =
        error.response?.data?.message ??
        "Error updating product. Please try again.";
      toast.error(errorMessage);
    }
  };
  const buildFolderStructure = (documents, startFolderName) => {
    let shouldStartBuilding = false;
    const structure = { folders: [], files: [] };
    for (const url of documents) {
      const parts = url.split("/");
      if (parts.includes(startFolderName)) {
        shouldStartBuilding = true;
      }
      if (!shouldStartBuilding) continue;

      let currentFolder = structure;
      let folderIndex = parts.findIndex((part) => part === startFolderName);
      for (let i = folderIndex + 1; i < parts?.length; i++) {
        const folderName = parts[i];
        if (i === parts?.length - 1) {
          currentFolder?.files?.push({ name: folderName, url });
        } else {
          let folder = currentFolder?.folders?.find(
            (f) => f.name === folderName
          );
          if (!folder) {
            folder = { name: folderName, folders: [], files: [] };
            currentFolder?.folders?.push(folder);
          }
          currentFolder = folder;
        }
      }
    }
    return structure;
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      cursor: "pointer",
    },
  }));

  const handleOpenDOCS = () => {
    setOpenDOC(true);
    if (product) {
      const folder = buildFolderStructure(product?.documents, product?._id);
      setCurrentFolder(folder);
      setPath([]);
      setFolderStructure(folder);
      console.log("current", currentFolder);
    }
  };
  const handleCloseDOCS = () => {
    setOpen(false);
    setOpenDOC(false);
  };
  const handleBreadcrumbClick = (index) => {
    const newPath = path.slice(0, index);
    setCurrentFolder(path[index]);
    setPath(newPath);
  };
  const handleFolderBackClick = () => {
    setPath([]);
    setCurrentFolder(folderStructure); // assuming initialFolder is the starting folder
  };

  const renderFolder = (folder) => {
    if (!folder || !folder.folders || !folder.files) {
      return null; // Return null or handle the case where folder or its properties are undefined
    }
    const handleFileClick = async (fileUrl) => {
      try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch the file");
        }

        const fileName = fileUrl.split("/").pop();
        const fileExtension = fileName.split(".").pop().toLowerCase();
        const blob = await response.blob();
        const reader = new FileReader();

        const viewableExtensions = [
          "pdf",
          "doc",
          "docx",
          "odt",
          "xls",
          "xlsx",
          "csv",
          "ppt",
          "pptx",
          "odp",
          "jpg",
          "png",
          "gif",
          "bmp",
          "tiff",
          "webp",
          "svg",
          "ai",
          "eps",
          "mp4",
          "avi",
          "wmv",
          "flv",
          "mkv",
          "webm",
          "mp3",
          "wav",
          "aac",
          "ogg",
          "flac",
          "wma",
          "midi",
          "txt",
          "rtf",
        ];

        if (viewableExtensions.includes(fileExtension)) {
          if (fileExtension === "pdf") {
            window.open(
              `https://docs.google.com/viewer?url=${encodeURIComponent(
                fileUrl
              )}`,
              "_blank"
            );
          } else if (
            ["doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(
              fileExtension
            )
          ) {
            window.open(
              `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                fileUrl
              )}`,
              "_blank"
            );
          } else if (fileExtension === "svg") {
            reader.onloadend = () => {
              const htmlContent = `<html><body style='margin:0;display:flex;justify-content:center;align-items:center;height:100vh;'><img src='${reader.result}' style='max-width:100%; max-height:100%;'></body></html>`;
              const blobHtml = new Blob([htmlContent], { type: "text/html" });
              window.open(URL.createObjectURL(blobHtml), "_blank");
            };
            reader.readAsDataURL(blob);
          } else if (
            ["jpg", "png", "gif", "bmp", "tiff", "webp"].includes(fileExtension)
          ) {
            reader.onloadend = () => {
              window.open(reader.result, "_blank");
            };
            reader.readAsDataURL(blob);
          } else if (
            ["mp4", "avi", "wmv", "flv", "mkv", "webm"].includes(fileExtension)
          ) {
            reader.onloadend = () => {
              const htmlContent = `<html><body><video controls autoplay style='width:100%;'><source src='${reader.result}' type='video/${fileExtension}'></video></body></html>`;
              const blobHtml = new Blob([htmlContent], { type: "text/html" });
              window.open(URL.createObjectURL(blobHtml), "_blank");
            };
            reader.readAsDataURL(blob);
          } else if (
            ["mp3", "wav", "aac", "ogg", "flac", "wma", "midi"].includes(
              fileExtension
            )
          ) {
            reader.onloadend = () => {
              const htmlContent = `<html><body><audio controls autoplay><source src='${reader.result}' type='audio/${fileExtension}'></audio></body></html>`;
              const blobHtml = new Blob([htmlContent], { type: "text/html" });
              window.open(URL.createObjectURL(blobHtml), "_blank");
            };
            reader.readAsDataURL(blob);
          } else {
            reader.onloadend = () => {
              const textContent = reader.result;
              const htmlContent = `<html><body><pre>${textContent}</pre></body></html>`;
              const blobHtml = new Blob([htmlContent], { type: "text/html" });
              window.open(URL.createObjectURL(blobHtml), "_blank");
            };
            reader.readAsText(blob);
          }
        } else {
          downloadFile(fileUrl, fileName);
        }
      } catch (error) {
        console.error("Error:", error);
        alert(`Unsupported file type. The file will be downloaded.`);
        downloadFile(fileUrl);
      }
    };

    const downloadFile = (fileUrl, fileName = fileUrl.split("/").pop()) => {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleFolderClick = (folder) => {
      setPath([...path, currentFolder]);
      setCurrentFolder(folder);
    };
    return (
      <div>
        {renderBreadcrumbs()}
        <div className="folder-files">
          {folder?.folders?.map((subfolder, index) => (
            <div
              key={index}
              onClick={() => handleFolderClick(subfolder)}
              className="folder-set"
            >
              <img src={docFolder} alt="" width={40} height={40} />
              <span className="doc-text">{subfolder.name}</span>
            </div>
          ))}
          {folder?.files?.map((file, index) => (
            <div
              key={index}
              className="folder-set"
              onClick={() => handleFileClick(file.url)}
            >
              <img src={pdf} alt="" width={40} height={40} />
              <span className="doc-text">{file.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderBreadcrumbs = () => {
    if (path.length === 0) return null;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "24px 24px 0px 24px",
        }}
      >
        <img
          src={docFolder}
          alt=""
          width={28}
          height={28}
          onClick={handleFolderBackClick}
          style={{ cursor: "pointer" }}
        />
        {path?.map((folder, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <div style={{ margin: "0 3px" }}>
                <img src={arrowIcon} alt="" />
              </div>
            )}
            <span
              className="selected-brand"
              onClick={() => handleBreadcrumbClick(index)}
              style={{ cursor: "pointer", margin: "0 3px" }}
            >
              {folder.name}
            </span>
          </React.Fragment>
        ))}
        <div style={{ margin: "0 3px" }}>
          <img src={arrowIcon} alt="" />
        </div>
        <span style={{ margin: "0 3px" }} className="selected-brand">
          {currentFolder.name}
        </span>
      </div>
    );
  };
  return (
    <div>
      <div className="productdetails-container">
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: "502px",
              height: "333px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "32px 80px",
            },
          }}
          sx={{ "& .MuiDialogContent-root": { overflow: "hidden" } }}
        >
          <img src={deletePopupIcon} alt="" width={100} height={100} />
          <DialogTitle id="alert-dialog-title">
            {"Are You Sure You Want to Delete ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This Product Will Be Deleted Immediately.
              <br /> You Can’t Undo This Action.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,
                color: "#26408A",
                padding: "0.5rem 1rem",
                cursor: "pointer",
                width: "140px",
                height: "40px",
                borderRadius: "20px",
                border: "2px solid transparent",
                backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #26408A, #196D92, #49883E)`,
                backgroundOrigin: "padding-box, border-box",
                backgroundClip: "padding-box, border-box",
                marginRight: "24px",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteProduct}
              autoFocus
              sx={{
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,
                padding: "0.5rem 1rem",
                cursor: "pointer",
                width: "140px",
                height: "40px",
                borderRadius: "20px",
                border: "2px solid transparent",
                backgroundColor: "#BA1A1A",
                color: "#fff",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#BA1A1A",
                  border: "2px solid #BA1A1A",
                  textTransform: "capitalize",
                },
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDOC}
          onClose={handleCloseDOCS}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            sx: {
              width: { xs: "100%", md: "759px" },
              height: { xs: "100%", md: "409px" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "hidden",
              "@media (max-width: 850px)": {
                width: "100%",
                height: "100%",
                maxHeight: "100%", // Apply only for <850px
                maxWidth: "100%", // Apply only for <850px
                margin: "0px", // Apply only for <850px
                borderRadius: "0px",
              },
            },
          }}
          sx={{ "& .MuiDialogContent-root": { overflow: "hidden" } }}
        >
          <div className="doc-nav">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <img src={docFolder} alt="" width={28} height={28} />
              <span style={{ fontSize: "18px", fontFamily: "Calibri" }}>
                Documents
              </span>
            </div>
            <div onClick={handleCloseDOCS} style={{ cursor: "pointer" }}>
              <img src={close} alt="" width={16} height={16} />
            </div>
          </div>
          <div>{openDOC && renderFolder(currentFolder)}</div>
        </Dialog>
        <TakeNotes
          notesOpen={notesOpen}
          handleNotesClose={handleNotesClose}
          productId={product?._id}
          base64Images={images}
          base64Loading={base64ProductsLoading}
        />
        <Selection
          openPopup={selectionOpen}
          closePopup={handleSelectionClose}
          product={product}
        />
        <PreviewImage
          openImagePopup={openImagePopup}
          selectedImage={selectedImage}
          handleCancel={handleCancel}
        />
        <div className="productdetails-functions">
          <div
            style={{
              width: "60%",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <div>
              {!backIsHovered && (
                <img
                  src={arrow}
                  alt=""
                  width={32}
                  height={32}
                  className="backicon"
                  onClick={handleDashboard}
                  onMouseEnter={() => setbackIsHovered(true)}
                  onMouseLeave={() => setbackIsHovered(false)}
                />
              )}
              {backIsHovered && (
                <img
                  src={arrowGif}
                  alt=""
                  width={32}
                  height={32}
                  className="backicon"
                  onClick={handleDashboard}
                  onMouseEnter={() => setbackIsHovered(true)}
                  onMouseLeave={() => setbackIsHovered(false)}
                />
              )}
            </div>
            <div>
              <span className="back-navigate">{product.description}</span>
            </div>
          </div>
          <div className="takenotes-align">
            {role !== "Marketing" && (
              <div className="icons-align">
                <div className="view-product-buttons" onClick={handleEditClick}>
                  <div
                    onMouseEnter={() => seteditIsHovered(true)}
                    onMouseLeave={() => seteditIsHovered(false)}
                  >
                    {!editIsHovered && (
                      <img
                        src={edit}
                        id="editIcon"
                        alt=""
                        width={34}
                        height={34}
                      />
                    )}
                    {editIsHovered && (
                      <img
                        src={editGif}
                        id="editIcon"
                        alt=""
                        width={34}
                        height={34}
                      />
                    )}
                  </div>
                  {!isSmallScreen && (
                    <div>
                      <span className="icon-text">Edit</span>
                    </div>
                  )}
                </div>
                <div
                  onMouseEnter={() => setDeleteIsHovered(true)}
                  onMouseLeave={() => setDeleteIsHovered(false)}
                  onClick={handleClickOpen}
                  className="view-product-buttons"
                >
                  <div>
                    {" "}
                    {!deleteIsHovered && (
                      <img
                        src={deleteIcon}
                        id="deleteIcon"
                        className="icon"
                        alt=""
                        width={32}
                        height={32}
                      />
                    )}
                    {deleteIsHovered && (
                      <img
                        src={deleteGif}
                        id="deleteIcon"
                        className="icon"
                        alt=""
                        width={32}
                        height={32}
                      />
                    )}
                  </div>
                  {!isSmallScreen && (
                    <div>
                      <span className="icon-text">Delete</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            {role !== "Operator" &&
              (user?._id === product?.selected_by ||
                product?.selected_by == null ||
                product?.selected_by === "" ||
                product?.selected_by === undefined) && (
                <div className="selection">
                  {!isSmallScreen && (
                    <div>
                      {selection.selection ? (
                        <button onClick={handleUnselect} className="selected">
                          Unselect the Product
                        </button>
                      ) : (
                        <button
                          className="unselected"
                          onClick={handleSelectionOpen}
                        >
                          Select the Product
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )}
            {role !== "Operator" && (
              <button className="takenotes-button" onClick={handleNotesOpen}>
                Take Notes
              </button>
            )}
          </div>
        </div>
        <div className="product-main">
          <div className="product-image-container">
            <div className="laptop">
              <div className="laptop-sub">
                <div className="side-card">
                  {product && product.front_image && (
                    <div
                      className="product-images"
                      style={{
                        border:
                          selectedImage === product.front_image
                            ? "3px solid transparent"
                            : "none",
                        background:
                          selectedImage === product.front_image
                            ? "linear-gradient(white, white) padding-box, linear-gradient(to right, #26408A, #196D92, #49883E) border-box"
                            : "linear-gradient(white, white) padding-box, linear-gradient(to right, transparent, transparent) border-box",
                      }}
                      onClick={() => handleImageClick(product.front_image)}
                    >
                      <div
                        className={`image-wrapper ${
                          selectedImage === product.front_image
                            ? "active-image"
                            : ""
                        }`}
                      >
                        <img
                          src={product.front_image}
                          alt=""
                          width="100%"
                          className="full-image"
                          loading="lazy"
                        />{" "}
                      </div>
                    </div>
                  )}
                  {product && product.back_image && (
                    <div
                      className="product-images"
                      style={{
                        border:
                          selectedImage === product.back_image
                            ? "3px solid transparent"
                            : "none",
                        background:
                          selectedImage === product.back_image
                            ? "linear-gradient(white, white) padding-box, linear-gradient(to right, #26408A, #196D92, #49883E) border-box"
                            : "linear-gradient(white, white) padding-box, linear-gradient(to right, transparent, transparent) border-box",
                      }}
                      onClick={() => handleImageClick(product.back_image)}
                    >
                      <div
                        className={`image-wrapper ${
                          selectedImage === product.back_image
                            ? "active-image"
                            : ""
                        }`}
                      >
                        <img
                          src={product.back_image}
                          alt=""
                          width="100%"
                          className="full-image"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  )}

                  {product.closeup_images.map((image, index) => (
                    <div
                      key={index}
                      className="product-images"
                      style={{
                        border:
                          selectedImage === image
                            ? "3px solid transparent"
                            : "none",
                        background:
                          selectedImage === image
                            ? "linear-gradient(white, white) padding-box, linear-gradient(to right, #26408A, #196D92, #49883E) border-box"
                            : "linear-gradient(white, white) padding-box, linear-gradient(to right, transparent, transparent) border-box",
                      }}
                      onClick={() => handleImageClick(image)}
                    >
                      <div
                        className={`image-wrapper ${
                          selectedImage === image ? "active-image" : ""
                        }`}
                      >
                        <img
                          src={image}
                          alt=""
                          width="100%"
                          className="full-image"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="image-align">
                  {product.best_seller && (
                    <div className="bestseller-icon">
                      <img src={BestSeller} alt="" width={44} height={57} />
                    </div>
                  )}
                  {product.selection && (
                    <BootstrapTooltip
                      title={`Selected By: ${product.selected_user}`}
                    >
                      <div
                        className="product-selected-icon"
                        style={{
                          display: "inline-block",
                        }}
                      >
                        <img
                          src={selectedIcon}
                          alt="Selected Icon"
                          width={30}
                          height={30}
                        />
                      </div>
                    </BootstrapTooltip>
                  )}
                  <div
                    className="full-image-container"
                    onClick={() => handleImageZoomClick(selectedImage)}
                  >
                    <div className="image-container-magnify">
                      <img
                        src={selectedImage}
                        alt="Zoomable"
                        className="full-image"
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                      />
                      {hoverPos && (
                        <div
                          className="zoom-overlay"
                          style={{
                            backgroundImage: `url(${selectedImage})`,
                            backgroundPosition: `${hoverPos.x}% ${hoverPos.y}%`,
                            transform: `translate(${hoverPos.overlayX}px, ${hoverPos.overlayY}px)`,
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="fabric-container">
                <div className="fabric-header">
                  <span className="fabric-info">Product Basic Info</span>
                </div>
                <div className="fabric-details-container">
                  {fabricDetails.map(({ label, value }, index) => (
                    <div className="fabric-align">
                      <div key={index} className="fabric-details">
                        <span className="fabric-label">{label}</span>
                        <span className="fabric-value">{value || "-"}</span>
                      </div>{" "}
                      <div className="fabric-line"></div>
                    </div>
                  ))}
                </div>
                {product.fabric_info.map((fabric, fabricIndex) => (
                  <div key={fabricIndex}>
                    <div className="fabric-header">
                      <span className="fabric-info">
                        Fabric {fabricIndex + 1} Info
                      </span>
                    </div>
                    <div className="fabric-details-container">
                      {[
                        {
                          label: "Fabric Reference. No",
                          value: fabric.fabric_ref,
                        },
                        { label: "Content", value: fabric.content },
                        { label: "Construction", value: fabric.construction },
                        {
                          label: "Fabric Mill / Supplier",
                          value: fabric.fabric_supplier,
                        },
                        { label: "Shrinkage", value: fabric.shrinkage },
                        {
                          label: `Weight (${fabric.weight_unit})`,
                          value: fabric.weight,
                        },
                        {
                          label: `Cuttable Width (${fabric.cw_unit})`,
                          value: fabric.cw,
                        },
                        {
                          label: `Fabric Price (${
                            fabric.fabric_currency || "USD"
                          })`,
                          value: fabric.fabric_price,
                        },
                        { label: "Yardage", value: fabric.yardage },
                      ].map(({ label, value }, index) => (
                        <div className="fabric-align">
                          <div key={index} className="fabric-details">
                            <span className="fabric-label">{label}</span>
                            <span className="fabric-value">{value || "-"}</span>
                          </div>{" "}
                          <div className="fabric-line"></div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                <div className="fabric-header">
                  <span className="fabric-info">Phase</span>
                </div>
                <div className="fabric-details-container">
                  {fabricProd.map(({ label, value }, index) => (
                    <div className="fabric-align">
                      <div key={index} className="fabric-details">
                        <span className="fabric-label">{label}</span>
                        <span className="fabric-value">{value || "-"}</span>
                      </div>
                      <div className="fabric-line"></div>
                    </div>
                  ))}
                </div>
                <div className="fabric-align">
                  <div className="fabric-details">
                    <span className="fabric-label">Documents</span>
                    <button
                      className="download-button"
                      onClick={() => handleOpenDOCS()}
                    >
                      <img src={documentIcon} alt="" />
                      View Documents
                    </button>
                  </div>
                  <div className="fabric-line"></div>
                </div>
              </div>
            </div>
            <div className="mobile">
              <div className="mobile-sub">
                <div className="image-align">
                  {product.best_seller && (
                    <div className="bestseller-icon">
                      <img src={BestSeller} alt="" width={44} height={57} />
                    </div>
                  )}

                  {product.selection && (
                    <div className="selected-icon">
                      <img src={selectedIcon} alt="" width={30} height={30} />
                    </div>
                  )}
                  <div
                    className="full-image-container"
                    onClick={() => handleImageZoomClick(selectedImage)}
                  >
                    <img
                      src={selectedImage}
                      alt=""
                      className="full-image"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="side-card">
                  {product && product.front_image && (
                    <div
                      className="product-images"
                      style={{
                        border:
                          selectedImage === product.front_image
                            ? "3px solid transparent"
                            : "none",
                        background:
                          selectedImage === product.front_image
                            ? "linear-gradient(white, white) padding-box, linear-gradient(to right, #26408A, #196D92, #49883E) border-box"
                            : "linear-gradient(white, white) padding-box, linear-gradient(to right, transparent, transparent) border-box",
                      }}
                      onClick={() => handleImageClick(product.front_image)}
                    >
                      <div
                        className={`image-wrapper ${
                          selectedImage === product.front_image
                            ? "active-image"
                            : ""
                        }`}
                      >
                        <img
                          src={product.front_image}
                          alt=""
                          width="100%"
                          className="full-image"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  )}
                  {product && product.back_image && (
                    <div
                      className="product-images"
                      style={{
                        border:
                          selectedImage === product.back_image
                            ? "3px solid transparent"
                            : "none",
                        background:
                          selectedImage === product.back_image
                            ? "linear-gradient(white, white) padding-box, linear-gradient(to right, #26408A, #196D92, #49883E) border-box"
                            : "linear-gradient(white, white) padding-box, linear-gradient(to right, transparent, transparent) border-box",
                      }}
                      onClick={() => handleImageClick(product.back_image)}
                    >
                      <div
                        className={`image-wrapper ${
                          selectedImage === product.back_image
                            ? "active-image"
                            : ""
                        }`}
                      >
                        <img
                          src={product.back_image}
                          alt=""
                          width="100%"
                          className="full-image"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  )}

                  {product.closeup_images.map((image, index) => (
                    <div
                      key={index}
                      className="product-images"
                      style={{
                        border:
                          selectedImage === image
                            ? "3px solid transparent"
                            : "none",
                        background:
                          selectedImage === image
                            ? "linear-gradient(white, white) padding-box, linear-gradient(to right, #26408A, #196D92, #49883E) border-box"
                            : "linear-gradient(white, white) padding-box, linear-gradient(to right, transparent, transparent) border-box",
                      }}
                      onClick={() => handleImageClick(image)}
                    >
                      <div
                        className={`image-wrapper ${
                          selectedImage === image ? "active-image" : ""
                        }`}
                      >
                        <img
                          src={image}
                          alt=""
                          width="100%"
                          className="full-image"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="fabric-container">
                <div className="fabric-header">
                  <span className="fabric-info">Product Basic Info</span>
                </div>
                <div className="fabric-details-container">
                  {fabricDetails.map(({ label, value }, index) => (
                    <div className="fabric-align">
                      <div key={index} className="fabric-details">
                        <span className="fabric-label">{label}</span>
                        <span className="fabric-value">{value || "-"}</span>
                      </div>{" "}
                      <div className="fabric-line"></div>
                    </div>
                  ))}
                </div>
                {product.fabric_info.map((fabric, fabricIndex) => (
                  <div key={fabricIndex}>
                    <div className="fabric-header">
                      <span className="fabric-info">
                        Fabric {fabricIndex + 1} Info
                      </span>
                    </div>
                    <div className="fabric-details-container">
                      {[
                        {
                          label: "Fabric Reference. No",
                          value: fabric.fabric_ref,
                        },
                        { label: "Content", value: fabric.content },
                        { label: "Construction", value: fabric.construction },
                        {
                          label: "Fabric Mill / Supplier",
                          value: fabric.fabric_supplier,
                        },
                        { label: "Shrinkage", value: fabric.shrinkage },
                        {
                          label: `Weight (${fabric.weight_unit})`,
                          value: fabric.weight,
                        },
                        {
                          label: `Cuttable Width (${fabric.cw_unit})`,
                          value: fabric.cw,
                        },
                        {
                          label: `Fabric Price (${
                            fabric.fabric_currency || "USD"
                          })`,
                          value: fabric.fabric_price,
                        },
                        { label: "Yardage", value: fabric.yardage },
                      ].map(({ label, value }, index) => (
                        <div className="fabric-align">
                          <div key={index} className="fabric-details">
                            <span className="fabric-label">{label}</span>
                            <span className="fabric-value">{value || "-"}</span>
                          </div>{" "}
                          <div className="fabric-line"></div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}

                <div className="fabric-header">
                  <span className="fabric-info">Phase</span>
                </div>
                <div className="fabric-details-container">
                  {fabricProd.map(({ label, value }, index) => (
                    <div className="fabric-align">
                      <div key={index} className="fabric-details">
                        <span className="fabric-label">{label}</span>
                        <span className="fabric-value">{value || "-"}</span>
                      </div>
                      <div className="fabric-line"></div>
                    </div>
                  ))}
                </div>
                <div className="fabric-align">
                  <div className="fabric-details">
                    <span className="fabric-label">Documents</span>
                    <button
                      className="download-button"
                      onClick={() => handleOpenDOCS()}
                    >
                      <img src={documentIcon} alt="" />
                      View Documents
                    </button>
                  </div>
                  <div className="fabric-line"></div>
                </div>
                {role !== "Operator" &&
                  (user?._id === product?.selected_by ||
                    product?.selected_by == null ||
                    product?.selected_by === "" ||
                    product?.selected_by === undefined) && (
                    <div className="selection">
                      <div>
                        {selection.selection ? (
                          <button onClick={handleUnselect} className="selected">
                            Unselect the Product
                          </button>
                        ) : (
                          <button
                            className="unselected"
                            onClick={handleSelectionOpen}
                          >
                            Select the Product
                          </button>
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSmallScreen && (
        <div className="select-product-div">
          {selection.selection ? (
            <button onClick={handleUnselect} className="selected bottom">
              Unselect the Product
            </button>
          ) : (
            <button className="unselected bottom" onClick={handleSelectionOpen}>
              Select the Product
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: state.product.product,
  loading: state.product.loading,
  base64ProductsLoading: state.getProducts.loading,
  deletedProduct: state.deletedProduct.product,
  selectedProductsData: state.getProducts.products,
});

const mapDispatchToProps = {
  findProduct,
  deleteProduct,
  findProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);

// {!selection.selection && (
//   <TextField
//     id="outlined-multiline-flexible"
//     name="comment"
//     label="Comment"
//     multiline
//     size="small"
//     fullWidth
//     maxRows={1}
//     value={selection.comment}
//     // onChange={handleChange}
//     onKeyDown={(e) => {
//       if (e.key === "Enter") {
//         e.preventDefault();
//         handleSelect(e); // Call submit on Enter key
//       }
//     }}
//     sx={{
//       "& .MuiOutlinedInput-notchedOutline": {
//         borderColor: "rgba(0, 0, 0, 0.8)",
//       },
//       "& .MuiInputLabel-root": {
//         color: "rgba(0, 0, 0, 0.8)",
//       },
//     }}
//   />
// )}
