import { combineReducers } from "redux";
import loginReducer from "./reducer/loginReducer";
import {
  addUserReducer,
  fetchUsersReducer,
  fetchUserReducer,
} from "./reducer/addUserReducer";
import {
  addProductReducer,
  getAllProductsReducer,
  getProductReducer,
  deleteProductReducer,
  getProductsDropDownValuesReducer,
  getTrendsBrandReducer,
  getBestSellerReducer,
  genderReducer,
  getProductsReducer,
  loadmoreReducer,
  getBrandDetailsReducer,
  getTrendBrandDetailsReducer,
  emailReducer,
  selectionReducer,
} from "./reducer/addProductReducer";
import {
  getBuildDemoStateReducer,
  setSelectedFilteredProductsReducer,
  setSelectedCheckedAttributesReducer,
} from "./reducer/otherReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  addUser: addUserReducer,
  user: fetchUserReducer,
  userDetails: fetchUsersReducer,
  addProduct: addProductReducer,
  allProducts: getAllProductsReducer,
  product: getProductReducer,
  deletedProduct: deleteProductReducer,
  dropdownValues: getProductsDropDownValuesReducer,
  trendsBrand: getTrendsBrandReducer,
  bestSeller: getBestSellerReducer,
  gender: genderReducer,
  getProducts: getProductsReducer,
  getBuildDemoState: getBuildDemoStateReducer,
  selectedFilteredProducts: setSelectedFilteredProductsReducer,
  selectedCheckedAttributes: setSelectedCheckedAttributesReducer,
  loadMore: loadmoreReducer,
  trendBrandDetails: getTrendBrandDetailsReducer,
  brandDetails: getBrandDetailsReducer,
  emailDetails: emailReducer,
  selectionDetails: selectionReducer,
});

export default rootReducer;
