import React, { useState, useEffect, useRef } from "react";
import { jsPDF } from "jspdf";
import { Dialog, DialogActions, CircularProgress } from "@mui/material";
import close from "../../images/icons/docClose.svg";
import "./TakeNotes.css";
import undoIcon from "../../images/undo-icon.png";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const GradientCircularProgress = () => (
  <div className="loading-container">
    <React.Fragment>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#26408A" />{" "}
            <stop offset="50%" stopColor="#196D92" />{" "}
            <stop offset="100%" stopColor="#49883E" />{" "}
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
    </React.Fragment>
  </div>
);

const TakeNotes = ({
  notesOpen,
  handleNotesClose,
  productId,
  base64Images,
  base64Loading,
}) => {
  const canvasRef = useRef(null);
  const isDrawing = useRef(false);
  const lastPosition = useRef({ x: 0, y: 0 });
  const drawings = useRef({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);
  const undoStacks = useRef([]);
  const [currentColor, setCurrentColor] = useState("#000000"); // Default color is black
  const [colorHistory, setColorHistory] = useState([]); // Array to store color history
  const padding = 80;

  // Combine all images into a single array
  useEffect(() => {
    setImages(base64Images);
  }, [base64Images]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // Adjust padding for small screens

  useEffect(() => {
    if (images.length > 0) {
      undoStacks.current = images.map(() => []); // Initialize undo stack for each image
    }
  }, [images]);

  useEffect(() => {
    if (!canvasRef.current || !images.length) return;
  
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
  
    // Set canvas size to match parent container
    const container = canvas.parentElement;
    canvas.width = container.offsetWidth;
    canvas.height = container.offsetHeight;
  
    const img = new Image();
    img.src = images[currentImageIndex];
  
    img.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      const scale = Math.min(
        (canvas.width - 2 * padding) / img.width,
        (canvas.height - 2 * padding) / img.height
      );
  
      const x = padding + (canvas.width - 2 * padding - img.width * scale) / 2;
      const y = padding + (canvas.height - 2 * padding - img.height * scale) / 2;
  
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
  
      if (drawings.current[currentImageIndex]) {
        ctx.putImageData(drawings.current[currentImageIndex], 0, 0);
      }
    };
  
    // Helper function to get coordinates for both touch and mouse events
    const getCanvasCoordinates = (event) => {
      const rect = canvas.getBoundingClientRect();
      let clientX, clientY;
  
      if (event.touches) {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
      } else {
        clientX = event.clientX;
        clientY = event.clientY;
      }
  
      return {
        x: clientX - rect.left,
        y: clientY - rect.top,
      };
    };
  
    const startDrawing = (e) => {
      e.preventDefault();
      isDrawing.current = true;
      lastPosition.current = getCanvasCoordinates(e);
    };
  
    const draw = (e) => {
      if (!isDrawing.current) return;
      e.preventDefault();
      const pos = getCanvasCoordinates(e);
  
      ctx.beginPath();
      ctx.moveTo(lastPosition.current.x, lastPosition.current.y);
      ctx.lineTo(pos.x, pos.y);
      ctx.strokeStyle = currentColor;
      ctx.lineWidth = 2;
      ctx.lineCap = "round";
      ctx.stroke();
  
      lastPosition.current = pos;
  
      setColorHistory((prev) => {
        if (!prev.includes(currentColor)) {
          return [currentColor, ...prev].slice(0, 5);
        }
        return prev;
      });
    };
  
    const stopDrawing = (e) => {
      if (!isDrawing.current) return;
      e.preventDefault();
      isDrawing.current = false;
  
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      drawings.current[currentImageIndex] = imageData;
      undoStacks?.current[currentImageIndex]?.push(imageData);
    };
  
    // Attach both mouse and touch events
    canvas.addEventListener("mousedown", startDrawing);
    canvas.addEventListener("mousemove", draw);
    canvas.addEventListener("mouseup", stopDrawing);
    canvas.addEventListener("mouseleave", stopDrawing);
  
    canvas.addEventListener("touchstart", startDrawing, { passive: false });
    canvas.addEventListener("touchmove", draw, { passive: false });
    canvas.addEventListener("touchend", stopDrawing);
    canvas.addEventListener("touchcancel", stopDrawing);
  
    return () => {
      canvas.removeEventListener("mousedown", startDrawing);
      canvas.removeEventListener("mousemove", draw);
      canvas.removeEventListener("mouseup", stopDrawing);
      canvas.removeEventListener("mouseleave", stopDrawing);
  
      canvas.removeEventListener("touchstart", startDrawing);
      canvas.removeEventListener("touchmove", draw);
      canvas.removeEventListener("touchend", stopDrawing);
      canvas.removeEventListener("touchcancel", stopDrawing);
    };
  }, [currentImageIndex, images, currentColor]);

  const undoDrawing = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (!undoStacks.current[currentImageIndex]?.length) return;

    // Remove the last drawing state from the stack
    undoStacks.current[currentImageIndex].pop();

    const previousState =
      undoStacks.current[currentImageIndex][
        undoStacks.current[currentImageIndex].length - 1
      ];

    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

    if (previousState) {
      ctx.putImageData(previousState, 0, 0); // Restore the previous state
    } else {
      // No previous state: redraw the original image with padding
      const img = new Image();
      img.src = images[currentImageIndex];
      img.onload = () => {
        const scale = Math.min(
          (canvas.width - 2 * padding) / img.width,
          (canvas.height - 2 * padding) / img.height
        );
        const x = padding + (canvas.width - 2 * padding - img.width * scale) / 2;
        const y = padding + (canvas.height - 2 * padding - img.height * scale) / 2;
        ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
      };
    }

    // Save the current canvas state back to drawings.current
    setTimeout(() => {
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      drawings.current[currentImageIndex] = imageData;
    }, 100); // Small delay to ensure image redraw completes
  };

  const handleClose = () => {
    handleNotesClose(); // Close the dialog
    // Reset state and refs
    setCurrentImageIndex(0);
    drawings.current = {};
    undoStacks.current = [];
  };

  const downloadAsPDF = () => {
    const doc = new jsPDF();
    const actualCanvas = canvasRef.current;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const promises = [];
    const margin = 10;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Scale temporary canvas to match the actual canvas size
    canvas.width = actualCanvas.width;
    canvas.height = actualCanvas.height;

    images.forEach((image, index) => {
      promises.push(
        new Promise((resolve) => {
          const img = new Image();
          img.src = image;

          img.onload = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Draw the image with padding
            const scale = Math.min(
              (canvas.width - 2 * padding) / img.naturalWidth,
              (canvas.height - 2 * padding) / img.naturalHeight
            );
            const x = padding + (canvas.width - 2 * padding - img.naturalWidth * scale) / 2;
            const y = padding + (canvas.height - 2 * padding - img.naturalHeight * scale) / 2;
            ctx.drawImage(
              img,
              x,
              y,
              img.naturalWidth * scale,
              img.naturalHeight * scale
            );

            // Apply drawings, scaling if necessary
            if (drawings.current[index]) {
              ctx.putImageData(drawings.current[index], 0, 0);
            }

            const dataURL = canvas.toDataURL("image/png");

            // Scale the image for the PDF while maintaining aspect ratio
            const imageAspectRatio = canvas.width / canvas.height;
            let displayWidth = pageWidth - 2 * margin;
            let displayHeight = displayWidth / imageAspectRatio;

            if (displayHeight > pageHeight - 2 * margin) {
              displayHeight = pageHeight - 2 * margin;
              displayWidth = displayHeight * imageAspectRatio;
            }

            const xOffset = (pageWidth - displayWidth) / 2;
            const yOffset = (pageHeight - displayHeight) / 2;

            if (index > 0) doc.addPage();
            doc.addImage(
              dataURL,
              "PNG",
              xOffset,
              yOffset,
              displayWidth,
              displayHeight
            );

            resolve();
          };
        })
      );
    });

    Promise.all(promises).then(() => {
      doc.save("images_with_drawings.pdf");
    });
  };

  const handleImageClick = (image) => {
    const index = images.indexOf(image);
    if (index !== -1) setCurrentImageIndex(index);
  };

  if (base64Loading) {
    return (
      <Dialog
        open={notesOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "100%",
            overflow: "hidden",
          },
        }}
      >
        <div className="loading-container">
          <GradientCircularProgress />
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={notesOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: "100%",
          height: "100%",
          maxHeight: isSmallScreen ? "100vh" : "90vh",
          maxWidth: isSmallScreen ? "100vw" : "auto",
          margin: isSmallScreen ? 0 : "32px",
          overflow: "hidden",
        },
      }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: "50%", // Set your width here
          },
        },
      }}
    >
      <div className="takenotes-container">
        <div className="adduser-nav">
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <span>Notes</span>
          </div>
          <div onClick={handleClose} style={{ cursor: "pointer" }}>
            <img src={close} alt="" width={16} height={16} />
          </div>
        </div>

        <div className="takenotes-main">
          <div className="notes-side-card">
            {images.map((image, index) => (
              <div
                key={index}
                className={`notes-images take-notes product-images ${
                  currentImageIndex === index ? "active-index" : ""
                }`}
                style={{
                  cursor: "pointer",
                  padding: "2px",
                }}
                onClick={() => handleImageClick(image)}
              >
                <div
                  className={`note-image-wrapper take-notes ${
                    currentImageIndex === index ? "active-index" : ""
                  }`}
                >
                  <img
                    className="full-image take-notes"
                    src={image}
                    alt={`Thumbnail ${index + 1}`}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="note-image-align">
            <div
              className="full-image-container"
              style={{ position: "relative" }}
            >
              <canvas
                ref={canvasRef}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  touchAction: "none",
                }}
              />
              <img
                src={images[currentImageIndex]}
                alt=""
                className="full-image draw-region"
                style={{ visibility: "hidden" }}
              />
            </div>
          </div>
        </div>

        <DialogActions
          sx={{
            padding: isSmallScreen ? "8px 16px" : "16px 24px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="takenotes-footer-buttons-container undo-color">
            <button
              className="undo-notes-button"
              onClick={undoDrawing}
              variant="outlined"
            >
              <img src={undoIcon} />
              {isSmallScreen ? "" : "Undo Note"}
            </button>

            <input
            className="color-input"
              type="color"
              value={currentColor}
              onChange={(e) => {
                setCurrentColor(e.target.value);
              }}
              // style={{backgroundColor:currentColor}}
              title={`Recent Colors: ${colorHistory.join(", ")}`} // Display color history in tooltip
            />

            {/* Color History */}
            <div
  style={{
    maxHeight: isSmallScreen ? "2.75rem" : "fit-content",
    overflowY: "auto",
    whiteSpace: "nowrap", // Prevents items from wrapping
  }}
>
  <div
    style={{
      display: isSmallScreen ? "grid" : "flex",
      gridTemplateColumns:"repeat(2, 1fr)",
      gap: "5px",
      minWidth: "max-content", // Ensures the inner div only takes the space it needs
    }}
  >
    {colorHistory.map((color, index) => (
      <div
        key={index}
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: color,
          border: "1px solid #ccc",
          cursor: "pointer",
          borderRadius: "50%",
        }}
        onClick={() => setCurrentColor(color)}
      />
    ))}
  </div>
</div>
          </div>

          <div className="takenotes-footer-buttons-container">
            <button
              onClick={handleClose}
              variant="outlined"
              className="popup-cancel-button take-notes"
            >
              Cancel
            </button>
            <button
              className="popup-confirm-button take-notes"
              onClick={downloadAsPDF}
            >
              Download Notes
            </button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default TakeNotes;